import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { getLanguageMap } from '../../../../../helpers/utils/languageMap.util'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { LocalizationSelectPopupComponent } from '../../../../../shared/popups/Lesson-Dialogs/localization-select/localization-select.component'

@Component({
  selector: 'app-localization-dropdown',
  templateUrl: './localization-dropdown.component.html',
  styleUrls: ['./localization-dropdown.component.scss'],
})
export class LocalizationDropdownComponent implements OnInit {
  // @Input() question: IQuestion;
  @Input() locales: Array<string>
  @Input() default: string
  @Output() setTranslation = new EventEmitter()

  constructor(private _dialog: MatDialog) {}

  public languageMap = getLanguageMap()

  public selectedTranslation = null

  ngOnInit(): void {
    const defaultTranslation = this.default
    this.selectedTranslation = defaultTranslation
    this.setTranslation.emit({ value: defaultTranslation })
  }

  /**
   * Get a filtered language map
   *
   * Should only return languages for which the given question has
   * localization data for (either in the question or its answers)
   */
  public get availableTranslations(): any {
    return this.languageMap.filter((languageMap) => {
      return this.locales.includes(languageMap.abbr)
    })
  }

  public openSelectDialog(): void {
    const dialog = this._dialog.open(LocalizationSelectPopupComponent, {
      hasBackdrop: true,
      width: '20rem',
      data: { availableTranslations: this.availableTranslations },
    })

    dialog.afterClosed().subscribe((language) => {
      if (language) {
        this.selectedTranslation = language.abbr
        this.setTranslation.emit({ value: language.abbr })
      }
    })
  }
}
