<section *ngIf="data; else elseBlock" class="timeReport">
  <div class="close-container"><span class="close" (click)="dialogRef.close()">+</span></div>
  <div class="name">{{ dialogData.log.subuser.name }}</div>

  <div class="title">{{ 'LINKS.PRACTICE' | translate }}</div>
  <article class="contentWrapper">
    <mat-form-field class="date-form-field">
      <mat-label>{{ 'PLACEHOLDERS.SELECT_DATE' | translate }}</mat-label>
      <mat-select [(value)]="selected">
        <mat-option *ngFor="let date of dateArray | keyvalue" (click)="getSubuserReport(date.key)" [value]="date.key | date : 'MMMM y'">
          {{ date.key | date : 'MMMM y' }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="infoBlock" *ngIf="subuserTimeLogs">
      <div class="info">
        <p class="main">
          {{ 'PRACTICE_REPORT.TOTAL' | translate }}:
          <span>{{ formatSeconds(subuserTimeLogs?.totalPracticeTime) }}</span>
        </p>
      </div>
      <div *ngIf="subuserTimeLogs" class="chart-content">
        <mat-tab-group mat-align-tabs="center">
          <mat-tab label="{{ 'PRACTICE_REPORT.DIALY' | translate }}">
            <canvas baseChart [data]="barChartData" [labels]="ChartLabels" [options]="barChartOptions" [type]="'bar'"> </canvas>
          </mat-tab>
          <mat-tab label="{{ 'PRACTICE_REPORT.TOTAL' | translate }}">
            <canvas baseChart [data]="lineChartData" [options]="lineChartOptions" [labels]="ChartLabels" [type]="'line'"> </canvas>
          </mat-tab>
        </mat-tab-group>
      </div>
      <table mat-table [dataSource]="dataSource" class="tableInfo">
        <ng-container matColumnDef="days">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'PRACTICE_REPORT.DAYS' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">{{ element.index }}</td>
        </ng-container>
        <ng-container matColumnDef="label">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'PRACTICE_REPORT.DATE' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.label | date : 'MMM d, y' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'PRACTICE_REPORT.DIALY' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ formatSeconds(element.value) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="totalTime">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'PRACTICE_REPORT.TOTAL' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ formatSeconds(element.totalTime) }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </article>
</section>

<ng-template #elseBlock>
  <div class="loader-back">
    <div class="loaderBlock">
      <div class="{{ loaderClass }}"></div>
    </div>
  </div>
</ng-template>
