import { Component } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'

@Component({
  templateUrl: './server-error.component.html',
  styleUrls: ['./server-error.component.scss'],
})
export class ServerErrorPopupComponent {
  constructor(
    public dialogRef: MatDialogRef<ServerErrorPopupComponent>,
  ) {}
}
