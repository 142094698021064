import { Component, OnInit, OnDestroy, Inject } from '@angular/core'
import { Router } from '@angular/router'
import { DOCUMENT } from '@angular/common'
import { SubUserStoreService } from "src/app/store/services/subuser-store.service"

@Component({
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit, OnDestroy {
  constructor(private router: Router,
    public subUserStoreService: SubUserStoreService,
    @Inject(DOCUMENT) private dom) {}

  public hideLocalizationDD = false

  ngOnInit() {
    let currentLang = this.subUserStoreService.currentLanguage

    if (currentLang === 'en') {
      const script = this.dom.createElement('script')
      script.id = 'chat-script'
      script.type = 'text/javascript'
      script.text = `!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});`
      this.dom.head.appendChild(script)

      const script2 = this.dom.createElement('script')
      script2.id = 'chat-script2'
      script2.type = 'text/javascript'
      script2.text = `window.Beacon('init', '58cc1226-6209-4851-8785-64769426c055')`
      this.dom.head.appendChild(script2)
    }
  }

  ngOnDestroy(): void {
    const el = this.dom.getElementById('chat-script')
    if (el) {
      el.remove()
    }
    const el2 = this.dom.getElementById('chat-script2')
    if (el2) {
      el2.remove()
    }
    const el3 = this.dom.getElementById('beacon-container')
    if (el3) {
      el3.remove()
    }
  }

  onActivate(event) {
    if (this.router.url.includes('terms-of-use') || this.router.url.includes('privacy-policy') || this.router.url.includes('/blog/')) {
      this.hideLocalizationDD = true
    } else {
      this.hideLocalizationDD = false
    }

    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset
      if (pos > 0) {
        window.scrollTo(0, pos - 75)
      } else {
        window.clearInterval(scrollToTop)
      }
    }, 16)
  }
}
