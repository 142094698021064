import { OnInit, Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { SEOService } from 'src/app/services/seo.service'
import { SiteBlockService } from '../../../services/siteBlock.service'

@Component({
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
})
export class BlogComponent implements OnInit {
  constructor(private _seoService: SEOService, private route: ActivatedRoute, private blockServise: SiteBlockService) {}

  public slug = ''
  public html = ''

  public dataLoaded = false

  ngOnInit() {
    const { meta } = this.route.snapshot.data
    this.slug = this.route.snapshot.params.slug

    this.blockServise.getPage(this.slug).subscribe(
      (data: any) => {
        if (data) {
          this.html = data.content
          this._seoService.updateTitle(data.title)
          this._seoService.updateDescription(data.description)
        }
        this.dataLoaded = true
      },
      (err) => {}
    )
  }
}
