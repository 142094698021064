import { Component, HostListener, OnInit, OnDestroy } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { ConfirmationPopupComponent } from '../../../../shared/popups/Control-Dialogs/confirmation/confirmation.component'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { AuthService } from '../../../../services/auth.service'
import { takeUntil } from 'rxjs/operators'
import { select, Store } from '@ngrx/store'
import { selectCurrentUser } from '../../../../store/selectors/user.selectors'
import { IAppState } from '../../../../store/state/app.state'
import { FormatTextService } from '../../../../services/formatText.service'
import { CurriculumDialogComponent } from '../../../../shared/popups/Curriculum-Dialogs/curriculum/curriculum.component'
import { environment } from 'src/environments/environment'
import { Subject } from 'rxjs'

@Component({
  selector: 'app-profile-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  constructor(
    public translate: TranslateService,
    private _dialog: MatDialog,
    public _authService: AuthService,
    private _store: Store<IAppState>,
    private textService: FormatTextService
  ) { }

  public user

  public displaySidebar = true

  profileTextColor = 'black'
  arrowImageSrc = '/assets/images/icons/right-arrow.svg'
  isMainMenuClicked = false
  isMyProfileClicked = false
  isUsersMenuItemClicked = false

  public SCP = environment.stripeCustomerPortal
  public unsubscribe$ = new Subject()

  ngOnInit() {
    this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentUser)).subscribe((user) => {
      this.user = user
    })
  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event): void {
    this.isMainMenuClicked = false
    this.isUsersMenuItemClicked = false
    this.isMyProfileClicked = false
  }

  public formatLangName(lang) {
    return this.textService.getFullLangName(lang)
  }

  toggleSidebar() {
    this.displaySidebar = !this.displaySidebar
  }

  logout() {
    const dialog = this._dialog.open(ConfirmationPopupComponent, {
      panelClass: 'medium-adaptive-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: 'MODAL_CONFIRM.LOG_OUT',
    })

    dialog.afterClosed().subscribe((answer) => {
      if (!answer) return

      this._authService.logout()
      window.location.href = environment.logoutRedirectUrl
    })
  }

  goToHelp() {
    window.open('https://help.dinolingo.com', '_blank').focus()
  }

  get teacherOrParent() {
    if (['teacherAdmin', 'teacher'].includes(this.user?.userType)) {
      return 'teachers'
    } else if (this.user?.userType === 'parent') {
      return 'parents'
    }

    return false
  }

  get logoLink() {
    if (['teacherAdmin', 'teacher'].includes(this.user?.userType)) {
      return '/profile/teachers/students'
    } else {
      return '/profile/lessons'
    }
  }

  openCurriculumDialog() {
    this._dialog.open(CurriculumDialogComponent, {
      panelClass: 'panel-curriculum-popup',
    })
  }

  mainMenuClicked(event) {
    event.stopPropagation()
    this.isMainMenuClicked = !this.isMainMenuClicked
  }

  myProfileClicked(event) {
    event.stopPropagation()
    this.isMyProfileClicked = !this.isMyProfileClicked
    this.isUsersMenuItemClicked = false
    if (this.isMyProfileClicked) {
      this.profileTextColor = '#F2994A'
      this.arrowImageSrc = '/assets/images/icons/right-arrow-orange.svg'
    } else {
      this.profileTextColor = 'black'
      this.arrowImageSrc = '/assets/images/icons/right-arrow.svg'
    }
  }

  usersMenuItemClicked(event) {
    event.stopPropagation()
    this.isUsersMenuItemClicked = !this.isUsersMenuItemClicked
    this.isMyProfileClicked = false
    if (this.isUsersMenuItemClicked) {
      this.profileTextColor = '#F2994A'
      this.arrowImageSrc = '/assets/images/icons/right-arrow-orange.svg'
    } else {
      this.profileTextColor = 'black'
      this.arrowImageSrc = '/assets/images/icons/right-arrow.svg'
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }
}
