import { AfterContentInit, Component, OnDestroy, ViewChild, OnInit } from '@angular/core'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar'
import { select, Store } from '@ngrx/store'
import { IAppState } from '../../../store/state/app.state'
import { Subject, Subscription, SubscriptionLike } from 'rxjs'
import { MatSort } from '@angular/material/sort'
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table'
import { take, takeUntil } from 'rxjs/operators'
import { selectTeachers } from '../../../store/selectors/teachers.selectors'
import { selectCurrentUser } from '../../../store/selectors/user.selectors'
import { GetTeachers, RemoveTeacher } from '../../../store/actions/teachers.actions'
import { ITeacher } from '../../../models/Teacher.model'
import { AddTeacherDialogComponent } from '../../../shared/popups/Teacher-Dialogs/add-teacher/add-teacher.component'
import { EditTeacherDialogComponent } from '../../../shared/popups/Teacher-Dialogs/edit-teacher/edit-teacher.component'
import { ConfirmationPopupComponent } from '../../../shared/popups/Control-Dialogs/confirmation/confirmation.component'
import { Actions, ofType } from '@ngrx/effects'
import { RemoveTeacherSuccess, ETeachersActions } from '../../../store/actions/teachers.actions'
import { NotificationService } from 'src/app/services/notification.service'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-teachers',
  templateUrl: './teachers.component.html',
  styleUrls: ['./teachers.component.scss'],
})
export class TeachersComponent implements AfterContentInit, OnDestroy, OnInit {

  constructor(
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _store: Store<IAppState>,
    private updates$: Actions,
    private _notificationService: NotificationService,
    public translate: TranslateService,
  ) { }

  private unsubscribe$ = new Subject()

  @ViewChild(MatSort) sort: MatSort

  public displayedColumns: string[] = ['index', 'firstName', 'email', 'role', 'actions']

  public dataSource: MatTableDataSource<ITeacher>

  public subscription: SubscriptionLike

  public subscriptions: Subscription = new Subscription()

  public loaded = false

  public teachers$

  public schoolId

  ngOnInit(): void {
    this.updates$.pipe(ofType<RemoveTeacherSuccess>(ETeachersActions.RemoveTeacherSuccess), takeUntil(this.unsubscribe$)).subscribe((res: RemoveTeacherSuccess) => {
      this._notificationService.showNotification(this.translate.instant('TEACHER.DELETED'), 2)
    })
  }

  ngAfterContentInit() {
    this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentUser)).subscribe((user) => {
      if (!user) { return }

      this.schoolId = user.user.schoolId
      this.teachers$ = this._store.pipe(takeUntil(this.unsubscribe$), select(selectTeachers))
      this._store.dispatch(new GetTeachers(this.schoolId))
      this.getTeachers()
    })
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  getTeachers() {
    this.subscriptions.add(
      this.teachers$.subscribe((res) => {
        this.loaded = true
        this.dataSource = new MatTableDataSource<ITeacher>(res as ITeacher[])
        this.dataSource.sort = this.sort;
      })
    )
  }

  openAddTeacherDialog() {
    this._dialog.open(AddTeacherDialogComponent, {
      disableClose: false,
      hasBackdrop: true,
      width: '28rem',
      data: {
        schoolId: this.schoolId,
      },
    })
  }

  editTeacher(teacher) {
    this._dialog.open(EditTeacherDialogComponent, {
      disableClose: false,
      hasBackdrop: true,
      width: '28rem',
      data: teacher,
    })
  }

  removeTeacher(teacher) {
    if (teacher.role === 'teacherAdmin') {
      return
    }

    const dialog = this._dialog.open(ConfirmationPopupComponent, {
      panelClass: 'medium-adaptive-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: {
        question: 'TEACHER.REMOVE_CONFIRM',
        extraConfirmation: true,
      },
    })

    dialog.afterClosed().subscribe((answer) => {
      if (answer === true) {
        this._store.dispatch(new RemoveTeacher(teacher.schoolId, teacher.id))
      }
    })
  }
}
