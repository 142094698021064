import { Location, DOCUMENT } from '@angular/common'
import { Component, OnInit, Inject, Input } from '@angular/core'
import { LangChangeEvent, TranslateService } from '@ngx-translate/core'
import { FormatTextService } from '../../../../services/formatText.service'
import { LocalizeHelperService } from 'src/app/services/localizHelper.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public currentLan: string

  public lURL

  @Input() hideLDD: boolean

  constructor(public translate: TranslateService, private textService: FormatTextService, private localizeHelperService: LocalizeHelperService, @Inject(DOCUMENT) private dom) {}

  ngOnInit(): void {
    this.currentLan = this.localizeHelperService.getLangForced()

    this.lURL = this.localizeHelperService.getURLLang()

    this.setDirAttr(this.currentLan)

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLan = event.lang
      this.lURL = this.localizeHelperService.getURLLang()
      this.setDirAttr(this.currentLan)
    })
  }

  public formatLangName(lang) {
    return this.textService.getFullLangName(lang)
  }

  setDirAttr(lang) {
    this.dom.body.setAttribute('dir', lang === 'ar' ? 'rtl' : 'ltr')
  }

  public getURLLangPart() {
    let p = this.lURL
    return p ? `/${p}` : ''
  }
}
