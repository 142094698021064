import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, OnChanges, ElementRef, ViewChild } from '@angular/core'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { ReportProblemPopupComponent } from '../../popups/Report-Dialogs/report-problem/report-problem.component'
import { IAnswer } from 'src/app/models/Answer.model'
import { ICourse } from '../../../models/Course.model'
import { IQuestion } from 'src/app/models/Question.model'
import { AudioService } from 'src/app/services/audio.service'
import { DefaultPopupSettings } from 'src/app/models/Dialog.model'
import { TranslateService } from '@ngx-translate/core'
import { RandomizeService } from 'src/app/services/randomize.service'
import { LocalizeHelperService } from 'src/app/services/localizHelper.service'

@Component({
  selector: 'text-to-audio',
  templateUrl: './text-to-audio.component.html',
  styleUrls: ['./text-to-audio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextToAudioGameComponent implements OnChanges {
  @Input() question: IQuestion
  @Input() test: boolean
  @Input() game: boolean
  @Input() translation: string
  @Input() localizations: Array<object>
  @Input() course: ICourse
  @Output() success = new EventEmitter()
  @Output() failed = new EventEmitter()

  @ViewChild('audiohelp') audiohelp: ElementRef

  public currentlang

  public dir = 'ltr'
  public dir2 = 'ltr'

  constructor(private _dialog: MatDialog, public translate: TranslateService, private _RandomizeService: RandomizeService, private localizeHelperService: LocalizeHelperService, private audioService: AudioService) {}

  public checked = false
  public selectedAnswer: IAnswer
  public questionId: number
  public answers = []

  ngOnChanges() {
    if (this.translation === 'ar' || this.translation === 'fa' || this.translation === 'he') {
      this.dir2 = 'rtl'
    } else {
      this.dir2 = 'ltr'
    }

    this.selectedAnswer = null
    this.checked = false

    if (this.answers.length > 0 && this.question.id != this.questionId) {
      this.answers = []
      this.createAnswersArray(this.question)
    }
  }

  ngOnInit(): void {
    if (this.course.slug === 'arabic' || this.course.slug === 'persian' || this.course.slug === 'hebrew') {
      this.dir = 'rtl'
    }

    this.currentlang = this.localizeHelperService.getLangForced()

    this.questionId = this.question.id
    this.createAnswersArray(this.question)
  }

  ngOnDestroy() {
    this.audioService.stopAllAudio()
  }

  public audiohelpPlay() {
    this.audiohelp.nativeElement.play()
  }

  public createAnswersArray(question) {
    for (let i = 0; i < question.answers.length; i++) {
      const answer = {
        audioSrc: question.answers[i].audioSrc,
        correct: question.answers[i].correct,
        id: question.answers[i].id,
        name: question.answers[i].name,
        pictureSrc: question.answers[i].pictureSrc,
        questionId: question.answers[i].questionId,
        text: question.answers[i].text,
      }
      this.answers.push(answer)
      this.audioService.preloadAudio(question.answers[i].audioSrc, question.answers[i].audioSrc)
    }

    this._RandomizeService.shuffle(this.answers)
  }

  public selectAnswer(answer) {
    if (this.checked && this.game) return this.playAudio(answer.audioSrc)
    if (this.checked && this.test) return
    this.selectedAnswer = answer
    this.playAudio(answer.audioSrc)
  }

  public setAnswer(answer) {
    if (answer.correct) {
      this.success.emit({ question: this.question, answer })
    } else {
      this.failed.emit({ question: this.question, answer })
    }
    this.audioService.stopAudio(this.question.audioSrc)
  }

  public playAudio(src) {
    this.audioService.playAudio(src, src)
  }

  public checkAnswer() {
    this.checked = true
    if (this.selectedAnswer.correct) {
      this.audioService.playSoundCorrect()
    } else {
      this.audioService.playSoundIncorrect()
    }
  }

  public get checkRightAnswer() {
    return this.selectedAnswer.correct
  }

  public openReportPopup(question) {
    this._dialog.open(ReportProblemPopupComponent, new DefaultPopupSettings(question))
  }
}
