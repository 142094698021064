import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { IAppState } from '../../store/state/app.state'
import { mergeMap, take } from 'rxjs/operators'
import { selectCurrentUser } from '../../store/selectors/user.selectors'
import { SchoolService } from '../../services/school.service'

@Injectable()
export class SchoolResolver implements Resolve<any> {
  constructor(private _store: Store<IAppState>, private _schoolService: SchoolService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return of(this._store.pipe(
      take(1),
      select(selectCurrentUser),
      mergeMap((user) => this._schoolService.getSchool(user.user.schoolId))
    ))
  }
}
