import { HttpRequest } from '@angular/common/http'

export interface IAuthState {
  accessToken: string
  latestRequest: HttpRequest<any>
}

export const InitialAuthState: IAuthState = {
  accessToken: localStorage.getItem('accessToken'),
  latestRequest: null,
}
