import { Action } from '@ngrx/store'
import { IUserOrStudent } from 'src/app/models/User.model'
import { IThumbnail } from 'src/app/models/Avatar.model'

export enum EUserActions {
  GetUser = '[User] Get User',
  GetUserSuccess = '[User] Get User Success',
  UpdateUser = '[User] Update User',
  UpdateUserSuccess = '[User] Update User Success',
  UpdateUserPassword = '[User] Update User Password',
  UpdateUserPasswordSuccess = '[User] Update User Password Success',
  UpdateUserAvatar = '[User] Update User Avatar',
  UpdateUserAvatarSuccess = '[User] Update User Avatar Success',
  DeleteUser = '[User] Delete User',
  DeleteUserSuccess = '[User] Delete User Success',
  DeleteUserFailure = '[User] Delete User Failure',
  ManageBooksAutoplay = '[User] Manage Books Autoplay',
  ManageBooksAutoplaySuccess = '[User] Manage Books Autoplay Success',
}

export class GetUser implements Action {
  public readonly type = EUserActions.GetUser
}

export class GetUserSuccess implements Action {
  public readonly type = EUserActions.GetUserSuccess
  constructor(public payload: IUserOrStudent) {}
}

export class UpdateUser implements Action {
  public readonly type = EUserActions.UpdateUser
  constructor(public payload) {}
}

export class UpdateUserSuccess implements Action {
  public readonly type = EUserActions.UpdateUserSuccess
  constructor(public payload: any) {}
}

export class UpdateUserPassword implements Action {
  public readonly type = EUserActions.UpdateUserPassword
  constructor(public payload: any) {}
}

export class UpdateUserPasswordSuccess implements Action {
  public readonly type = EUserActions.UpdateUserPasswordSuccess
  constructor(public payload: any) {}
}

export class ManageBooksAutoplay implements Action {
  public readonly type = EUserActions.ManageBooksAutoplay
  constructor(public payload) {}
}

export class ManageBooksAutoplaySuccess implements Action {
  public readonly type = EUserActions.ManageBooksAutoplaySuccess
  constructor(public payload: any) {}
}

export class UpdateUserAvatar implements Action {
  public readonly type = EUserActions.UpdateUserAvatar
  constructor(public payload: IThumbnail) {}
}

export class UpdateUserAvatarSuccess implements Action {
  public readonly type = EUserActions.UpdateUserSuccess
  constructor(public payload: any) {}
}

export class DeleteUser implements Action {
  public readonly type = EUserActions.DeleteUser
  constructor() {}
}

export class DeleteUserSuccess implements Action {
  public readonly type = EUserActions.DeleteUserSuccess
  constructor(public payload: any) {}
}

export class DeleteUserFailure implements Action {
  public readonly type = EUserActions.DeleteUserFailure
  constructor(public payload: any) {}
}

export type UserActions = GetUser | GetUserSuccess | UpdateUser | ManageBooksAutoplay | ManageBooksAutoplaySuccess | UpdateUserSuccess | UpdateUserPassword | UpdateUserPasswordSuccess | UpdateUserAvatar | UpdateUserAvatarSuccess
