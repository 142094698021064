import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { DOCUMENT } from '@angular/common'
import { GetUser } from 'src/app/store/actions/user.actions'
import { Store } from '@ngrx/store'
import { IAppState } from 'src/app/store/state/app.state'

@Component({
  templateUrl: './successfully.component.html',
  styleUrls: ['./successfully.component.scss'],
})
export class SubscriptionsSuccessfullyComponent implements OnInit, OnDestroy {

  constructor(
    public translate: TranslateService,
    public router: Router,
    @Inject(DOCUMENT) private dom,
    private _store: Store<IAppState>,
  ) { }

  ngOnInit() {
    const script = this.dom.createElement('script')
    script.id = 'conversion-script'
    script.text = `{gtag('event', 'conversion', {'send_to': 'AW-957010986/2TLICMCQ-I4DEKqoq8gD'});}`
    this.dom.head.appendChild(script)
  }

  ngOnDestroy(): void {
    this._store.dispatch(new GetUser())
  }
}
