import { Component, OnInit, Inject } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { LangChangeEvent, TranslateService } from '@ngx-translate/core'
import { Meta } from '@angular/platform-browser'
import { DOCUMENT } from '@angular/common'
import { GetIpUserService } from 'src/app/services/getIp.service'
import { SEOService } from 'src/app/services/seo.service'
import { langForms } from 'src/assets/i18n/langforms'
import { LocalizeHelperService } from 'src/app/services/localizHelper.service'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { VideoPopupComponent } from 'src/app/shared/popups/VideoPopup/videopopup.component'
import { SubUserStoreService } from "src/app/store/services/subuser-store.service"

@Component({
  templateUrl: './learnForKids.component.html',
  styleUrls: ['./learnForKids.component.scss'],
})
export class LearnForKidsComponent implements OnInit {
  constructor(
    @Inject(DOCUMENT) private document,
    private _dialog: MatDialog,
    private router: Router,
    public translate: TranslateService,
    private meta: Meta,
    private getUserIpService: GetIpUserService,
    private route: ActivatedRoute,
    private _seoService: SEOService,
    private subUserStoreService: SubUserStoreService,
    private localizeHelperService: LocalizeHelperService
  ) {}

  public language: string = this.router.url.split('-')[1]
  public name: string
  public currentLang: string
  public title: string
  public description: string
  public useCurrentLang: string = this.language // .substring(1);

  public metaTwitterCard = 'summary_large_image'
  public metaTwitterSite = '@dinolingo'
  public metaTwitterImage = 'https://dinolingo.com/wp-content/uploads/2018/08/responsive-devices-flat.png'
  public metaTwitterCreator = '@dinolingo'
  public metaOgLocale = 'en_US'
  public metaOgType = 'article'
  public metaOgSite = 'DinoLingo®'
  public metaOgSecureUrl = 'https://dinolingo.com/wp-content/uploads/2018/08/responsive-devices-flat.png'
  public metaOgImage = 'https://dinolingo.com/wp-content/uploads/2018/08/responsive-devices-flat.png'
  public metaArticlePublisher = 'https://www.facebook.com/DinoLingo.1'

  public lang

  public learnForTranslations = []

  public metaTitle = ''

  public url: string = this.router.url

  public langParam = this.route.snapshot.params && this.route.snapshot.params.lang ? this.route.snapshot.params.lang : ''

  public lURL

  public isAdvText = true
  // public advTextLangs = [
  //   "spanish",
  //   "arabic",
  //   "armenian",
  //   "cantonese",
  //   "chinese",
  //   "creole",
  //   "english",
  //   "french",
  //   "german",
  //   "japanese",
  //   "korean",
  //   "latin",
  //   "slovenian",
  //   "swahili"
  // ];

  // Video Tutorial for different learn for kids pages
  public videoTutorial = {
    spanish: {
      text: 'Spanish App for Kids - Sample Video',
      url: 'https://www.youtube.com/embed/YBghT23o4dY',
    },
  }

  ngOnInit() {
    const request = {
      url: this.url,
    }
    const { meta } = this.route.snapshot.data

    this.localizeHelperService.setURLLang(this.langParam)
    this.currentLang = this.localizeHelperService.getLangForced()
    this.lURL = this.localizeHelperService.getURLLang()

    if (this.currentLang !== 'en') {
      this.isAdvText = false
    }

    const localMeta = meta[this.currentLang] == undefined ? meta['en'] : meta[this.currentLang]

    this.lang = this.language == 'euportuguese' ? 'European Portuguese' : this._capitalizeFirstLetter(this.language)

    let tt = this.formatWithForm(localMeta.title)
    this._seoService.updateTitle(tt)
    this._seoService.updateDescription(this.formatWithForm(localMeta.description))
    let canonicalPart = this.router.url.split('?')
    this._seoService.createCanonicalLink(localMeta.canonical.replace(/%s/g, canonicalPart[0]))
    this.localizeHelperService.createLangMetaLinks()

    this.meta.updateTag({ name: 'twitter:title', content: tt })
    this.meta.updateTag({ name: 'og:title', content: tt })

    this.getUserIpService.setReviewSnippet(request, this.formatWithForm(localMeta.reachName), this.document)

    this.meta.updateTag({
      name: 'twitter:card',
      content: this.metaTwitterCard,
    })
    this.meta.updateTag({
      name: 'twitter:site',
      content: this.metaTwitterSite,
    })
    this.meta.updateTag({
      name: 'twitter:image',
      content: this.metaTwitterImage,
    })
    this.meta.updateTag({
      name: 'twitter:creator',
      content: this.metaTwitterCreator,
    })
    this.meta.updateTag({ name: 'og:locale', content: this.metaOgLocale })
    this.meta.updateTag({ name: 'og:type', content: this.metaOgType })
    this.meta.updateTag({ name: 'og:site_name', content: this.metaOgSite })
    this.meta.updateTag({ name: 'og:url', content: document.location.href })
    this.meta.updateTag({ name: 'og:image', content: this.metaOgImage })
    this.meta.updateTag({
      name: 'og:image:secure_url',
      content: this.metaOgSecureUrl,
    })
    this.meta.updateTag({
      name: 'article:publisher',
      content: this.metaArticlePublisher,
    })

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLang = event.lang
      let tt = this.formatWithForm(meta[event.lang]['title'])
      this._seoService.updateTitle(tt)
      this._seoService.updateDescription(this.formatWithForm(meta[event.lang]['description']))
      this.lURL = this.localizeHelperService.getURLLang()

      this.meta.updateTag({ name: 'twitter:title', content: tt })
      this.meta.updateTag({ name: 'og:title', content: tt })
    })
  }

  public formatText(text, replacement) {
    return text.replace(/%s/g, replacement)
  }

  public formatWithForm(text) {
    if (!langForms[this.currentLang]) {
      return text
    }
    let simpleReplace = text.replace(/%s/g, langForms[this.currentLang][this.language]['form1'])
    let other = simpleReplace.match(/%f[0-9]/g)
    if (other) {
      other.forEach((element, i) => {
        let form = `form${element.replace('%f', '')}`
        let replacement = Object.keys(langForms[this.currentLang]).length === 0 ? this.lang : langForms[this.currentLang][this.language][form]
        simpleReplace = simpleReplace.replace(element, replacement)
      })
    }
    return simpleReplace
  }

  private _capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  public getURLLangPart() {
    let p = this.lURL
    return p ? `/${p}` : ''
  }

  public openVideoPopup(url) {
    const dialog = this._dialog.open(VideoPopupComponent, {
      panelClass: 'video-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: url,
    })
  }
}
