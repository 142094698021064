<section class="progress-reports">
  <div class="title">{{ 'LINKS.PROGRESS' | translate }}</div>

  <div class="header">
    <div class="dropdowns">
      <div class="form-fields-container half-width" *ngIf="user?.userType === 'parent' || ((user?.userType === 'teacherAdmin' || user?.userType === 'teacher') && classrooms.length)">
        <div class="form-field-container">
          <label for="selectCourse">{{ 'PROGRESS_REPORTS.CHOOSE_COURSE' | translate }}</label>
          <mat-select [(value)]="selectedCourse" placeholder="{{ 'PLACEHOLDERS.AGE' | translate }}">
            <mat-option *ngFor="let course of courses" (click)="selectCourse(course)" [value]="course">{{ formatLangName(course) }}</mat-option>
          </mat-select>
        </div>
      </div>

      <section *ngIf="(user?.userType === 'teacherAdmin' || user?.userType === 'teacher') && !classrooms.length">
        <span>{{ 'PROGRESS_REPORTS.MUST_ADD_CLASSROOM' | translate }}</span>
      </section>

      <div class="form-fields-container half-width" *ngIf="(user?.userType === 'teacherAdmin' || user?.userType === 'teacher') && classrooms.length">
        <div class="form-field-container">
          <label for="selectedClassroom">{{ 'PROGRESS_REPORTS.CHOOSE_CLASSROOM' | translate }}</label>
          <mat-select [(value)]="selectedClassroom">
            <mat-option *ngFor="let classroom of classroomsOptions" (click)="selectClassroom(classroom)" [value]="classroom">{{ classroom.classroomName }}</mat-option>
          </mat-select>
        </div>
      </div>

      <div class="form-fields-container half-width" *ngIf="(user?.userType === 'teacherAdmin' || user?.userType === 'teacher') && classrooms.length">
        <div class="form-field-container">
          <label for="selectedSort">{{ 'PROGRESS_REPORTS.CHOOSE_SORT' | translate }}</label>
          <mat-select [(value)]="selectedSort">
            <mat-option *ngFor="let sort of sortOptions" (click)="selectSort(sort)" [value]="sort">{{ sort.label }}</mat-option>
          </mat-select>
        </div>
      </div>

      <div class="export" *ngIf="(user?.userType === 'teacherAdmin' || user?.userType === 'teacher') && classrooms.length">
        <button type="button" class="btn-export" (click)="openExportDialog()"><img src="assets/images/icons/download.svg" alt="export reports" />{{ 'PROGRESS_REPORTS.EXPORT.TITLE' | translate }}</button>
      </div>
    </div>

    <div class="progress-reports-tabs">
      <div class="progress-reports-tab" [ngClass]="{ active: activeTab === 'test-results' }" (click)="activateTab('test-results')">{{ 'PROGRESS_REPORTS.TABS.TEST_RESULTS' | translate }}</div>
      <div class="progress-reports-tab" [ngClass]="{ active: activeTab === 'practice-time' }" (click)="activateTab('practice-time')">{{ 'PROGRESS_REPORTS.TABS.PRACTICE_TIME' | translate }}</div>
      <div class="progress-reports-tab" [ngClass]="{ active: activeTab === 'detailed-reports' }" (click)="activateTab('detailed-reports')">{{ 'PROGRESS_REPORTS.TABS.DETAILED_REPORTS' | translate }}</div>
    </div>
  </div>

  <div *ngIf="activeTab === 'test-results'">
    <app-test-results [type]="type" [classroom]="selectedClassroom" [course]="selectedCourse" [sortMethod]="selectedSort.method" [assignments]="assignments"></app-test-results>
  </div>
  <div *ngIf="activeTab === 'practice-time'">
    <app-practice-time [type]="type" [classroom]="selectedClassroom" [sortMethod]="selectedSort.method"></app-practice-time>
  </div>
  <div *ngIf="activeTab === 'detailed-reports'">
    <app-detailed-reports [type]="type" [classroom]="selectedClassroom" [course]="selectedCourse" [sortMethod]="selectedSort.method" [assignments]="assignments"></app-detailed-reports>
  </div>

  <div *ngIf="type === 'school' && classroomsOptions.length === 0">
    {{ 'PROGRESS_REPORTS.NO_CLASSROOMS' | translate }}
  </div>
</section>
