import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'
import { SubUserStoreService } from "src/app/store/services/subuser-store.service"

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private _router: Router,
    public subUserStoreService: SubUserStoreService) {}

  canActivate() {
    const jwtToken = localStorage.getItem('accessToken') || null
    if (jwtToken) {
      return true
    }
    this._router.navigate([`/auth/login`]) //NAV TO LOGIN
  }
}
