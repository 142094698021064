<div class="export-reports">
  <div class="actions">
    <span class="title">{{ 'PROGRESS_REPORTS.EXPORT.TITLE' | translate }} </span>
    <span class="close" (click)="dialogRef.close()">+</span>
  </div>
  <div class="content">
    <div class="form">
      <mat-form-field class="date-range">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Start date" (dateChange)="startDate = $event.targetElement.value" />
          <input matEndDate placeholder="End date" (dateChange)="endDate = $event.targetElement.value" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'PROGRESS_REPORTS.EXPORT.CHOOSE_FORMAT' | translate }}</mat-label>
        <mat-select [(value)]="selectedFormat">
          <mat-option *ngFor="let format of formats" [value]="format">{{ format }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="save">
    <button mat-raised-button color="accent" [disabled]="!(selectedFormat && startDate && endDate && dateValid(startDate) && dateValid(endDate))" (click)="export()">
      {{ 'PROGRESS_REPORTS.EXPORT.DOWNLOAD' | translate }}
    </button>
  </div>
</div>
