<section class="parents">
  <div *ngIf="!isMobileScreen">
    <app-profile-sidebar></app-profile-sidebar>
  </div>

  <section class="content">
    <div class="closeBtn" *ngIf="isMobileScreen">
      <button mat-icon-button routerLink="/profile/dashboard" color="warn">
        <img src="/assets/images/icons/closeIcon.svg" alt="close" />
      </button>
    </div>
    <div class="closeBtn" *ngIf="!isMobileScreen">
      <button mat-icon-button routerLink="/profile/lessons" color="warn" *ngIf="!['teacherAdmin', 'teacher'].includes(user?.userType)">
        <img src="/assets/images/icons/closeIcon.svg" alt="close" />
      </button>
    </div>

    <div class="title" *ngIf="user?.userType === 'teacherAdmin'">
      <h3 class="title-heading">{{ 'CLASSROOM.TEACHER_ADMIN_ACCOUNT' | translate }}</h3>
    </div>
    <div class="title" *ngIf="user?.userType === 'teacher'">
      <h3 class="title-heading">{{ 'CLASSROOM.TEACHER_ACCOUNT' | translate }}</h3>
    </div>

    <router-outlet class="full-width"></router-outlet>
  </section>
</section>
