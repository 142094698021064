import { Component, OnInit, OnDestroy, HostListener } from '@angular/core'
import { Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { IAppState } from 'src/app/store/state/app.state'
import { HideHeaderLesson, DisplayHeaderLesson } from 'src/app/store/actions/lessons.actions'
import { TranslateService } from '@ngx-translate/core'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { DefaultPopupSettings } from 'src/app/models/Dialog.model'
import { ReportProblemPopupComponent } from 'src/app/shared/popups/Report-Dialogs/report-problem/report-problem.component'
import { take, takeUntil } from 'rxjs/operators'
import { selectCurrentUser } from '../../../store/selectors/user.selectors'
import { DeviceDetectorService } from 'ngx-device-detector'
import { Subject } from 'rxjs'

@Component({
  templateUrl: './parents.component.html',
  styleUrls: ['./parents.component.scss'],
})
export class ParentsComponent implements OnInit, OnDestroy {
  constructor(private router: Router, private _store: Store<IAppState>, public translate: TranslateService, private _dialog: MatDialog, private deviceService: DeviceDetectorService) {}

  public user
  isMobileScreen: boolean = false
  public unsubscribe$ = new Subject()

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    if (this.deviceService.isMobile()) {
      this.checkScreenSize()
    }
  }

  ngOnInit() {
    if (this.deviceService.isMobile()) {
      this.checkScreenSize()
    }
    this._store.dispatch(new HideHeaderLesson())
    this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentUser)).subscribe((user) => {
      this.user = user
    })
    
  }

  private checkScreenSize(): void {
    this.isMobileScreen = window.outerHeight < 500 || window.outerWidth < 500
  }

  ngOnDestroy() {
    this._store.dispatch(new DisplayHeaderLesson())
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  public openReportPopup() {
    this._dialog.open(ReportProblemPopupComponent, new DefaultPopupSettings())
  }
}
