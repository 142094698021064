import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class SubUserStoreService {
  public userId: any
  public currentSubuser: any
  public subuserCurrentCourse = {}
  public currentLanguage: any
}