import { Injectable } from '@angular/core'
import * as moment from 'moment'
import { Router } from '@angular/router'
import { HttpClient } from '@angular/common/http'
import { IAppState } from 'src/app/store/state/app.state'
import { Store } from '@ngrx/store'
import { environment } from 'src/environments/environment'
import { IUser } from '../models/User.model'
import { ISubuser } from '../models/Subuser.model'

@Injectable({
  providedIn: 'root',
})
export class TimeLogService {
  private gatewayUrl: string
  constructor(private router: Router, private http: HttpClient, private _store: Store<IAppState>) {
    this.gatewayUrl = `${environment.gatewayUrl}log/`
  }

  public dateStart: any
  public currentUrl: string
  public currentUser: IUser
  public currentSubuser: ISubuser

  public logNgOnInit() {
    this.dateStart = moment()
    this.currentUrl = this.router.url
  }

  public logNgOnDestroy(subuserId = null) {
    // TODO: Remove logs after tests on Stage
    const dateEnd = moment()
    const durationInSeconds = dateEnd.diff(this.dateStart, 'seconds');

    if(subuserId) {
      const request = {
        subuser: {
          id: subuserId
        },
        url: this.currentUrl,
        period: durationInSeconds,
      }
      this.http.post(`${this.gatewayUrl}save`, request).subscribe()
    } else { // Remove it if NEW LOGIC IS OK
      console.error('TimeLogService subuserId is not defined.')
    }
  }

  public getTimeLog(request) {
    return this.http.get(`${this.gatewayUrl}get`, {
      params: {
        subuserId: request,
      },
    })
  }

  public getSelectedTimeLog(request) {
    return this.http.get(`${this.gatewayUrl}get`, {
      params: {
        subuserId: request.subuserId,
        firstDay: request.firstDay,
        lastDay: request.lastDay,
      },
    })
  }
}
