import { Component, Inject, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { Subject } from 'rxjs'
import { Actions, ofType } from '@ngrx/effects'
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { Store } from '@ngrx/store'
import { IAppState } from '../../../../store/state/app.state'
import { TranslateService } from '@ngx-translate/core'
import { filter, takeUntil } from 'rxjs/operators'
import { AvatarSelectPopupComponent } from '../../Subuser-Dialogs/avatar-select/avatar-select.component'
import { IStudent, IStudentRequest } from '../../../../models/Student.model'
import { EStudentActions, UpdateStudent, UpdateStudentSuccess } from '../../../../store/actions/students.actions'
import { NotificationService } from 'src/app/services/notification.service'

@Component({
  templateUrl: './edit-student.component.html',
  styleUrls: ['./edit-student.component.scss'],
})
export class EditStudentDialogComponent implements OnInit {
  public studentForm: UntypedFormGroup

  private destroyed$ = new Subject<boolean>()

  public unsubscribe$ = new Subject()

  constructor(
    private updates$: Actions,
    private _dialog: MatDialog,
    public dialogRef: MatDialogRef<EditStudentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public student: IStudent,
    private _store: Store<IAppState>,
    public translate: TranslateService,
    private _notificationService: NotificationService,
  ) {
    this.updates$.pipe(ofType<UpdateStudentSuccess>(EStudentActions.UpdateStudentSuccess), takeUntil(this.destroyed$)).subscribe((res: UpdateStudentSuccess) => {
      this.dialogRef.close(res)
      this._notificationService.showNotification(this.translate.instant('STUDENT.EDITED'), 2)
    })
  }

  public avatars = []

  ngOnInit() {
    this.avatars.unshift(this.student.subuser.avatar.src)
    this.studentForm = new UntypedFormGroup({
      avatarId: new UntypedFormControl(this.student.subuser.avatar.id),
      avatar: new UntypedFormControl(this.student.subuser.avatar),
      nickname: new UntypedFormControl(this.student.subuser.name, [Validators.required, Validators.minLength(3)]),
      studentCode: new UntypedFormControl(this.student.studentCode, [Validators.minLength(4), Validators.maxLength(10)]),
    })
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

  updateStudent(form: UntypedFormGroup) {
    if (form.invalid) return
    const request: IStudentRequest = form.value
    this._store.dispatch(new UpdateStudent(this.student, request))
  }

  openSelectAvatarPopup() {
    const dialog = this._dialog.open(AvatarSelectPopupComponent, {
      hasBackdrop: true,
      width: '32rem',
      height: '32rem',
    })

    dialog
      .afterClosed()
      .pipe(filter((avatar) => avatar !== null))
      .subscribe((avatar) => {
        if (avatar) {
          this.avatars.unshift(avatar.src)
          this.studentForm.patchValue({
            avatar,
            avatarId: avatar.id,
          })
        }
      })
  }

  public isLottie(url) {
    if (url.includes('.json')) {
      return true
    }
    return false
  }
}
