import { Injectable } from '@angular/core'
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Store, Action } from '@ngrx/store'
import { IAppState } from '../../store/state/app.state'
import { Actions, ofType } from '@ngrx/effects'
import { Observable, of } from 'rxjs'
import { take } from 'rxjs/operators'
import { GetAllSubuser, GetAllSubuserSuccess, ESubuserActions } from '../../store/actions/subuser.actions'

@Injectable()
export class SubuserResolver implements Resolve<Observable<Action>> {
  constructor(private store: Store<IAppState>, private action$: Actions) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Observable<Action>> {
    this.store.dispatch(new GetAllSubuser())

    return of(this.action$.pipe(ofType<GetAllSubuserSuccess>(ESubuserActions.GetAllSubuserSuccess), take(1)))
  }
}
