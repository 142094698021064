import { Component } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { Router } from '@angular/router'
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'

@Component({
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent {
  constructor(
    public translate: TranslateService,
    private _router: Router,
    public dialogRef: MatDialogRef<UnauthorizedComponent>) {}
  clear() {
    localStorage.clear()
    this._router.navigate(['/auth/login'])
    this.dialogRef.close()
  }
}
