import { Component, OnDestroy, OnInit } from '@angular/core'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { Store, select } from '@ngrx/store'
import { IAppState } from 'src/app/store/state/app.state'
import { selectCurrentUser } from 'src/app/store/selectors/user.selectors'
import { Observable, Subject } from 'rxjs'
import { UpdateUserAvatar, UpdateUser, DeleteUser, EUserActions, DeleteUserSuccess, DeleteUserFailure } from 'src/app/store/actions/user.actions'
import { filter, takeUntil } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'
import { AvatarSelectPopupComponent } from 'src/app/shared/popups/Subuser-Dialogs/avatar-select/avatar-select.component'
import { EditProfileDialogComponent } from 'src/app/shared/popups/Parent-Dialogs/editProfile/editProfile.component'
import { ChangePasswordDialogComponent } from 'src/app/shared/popups/Parent-Dialogs/changePassword/changePassword.component'
import { Router } from '@angular/router'
import { ConfirmationPopupComponent } from 'src/app/shared/popups/Control-Dialogs/confirmation/confirmation.component'
import { AuthService } from 'src/app/services/auth.service'
import { environment } from 'src/environments/environment'
import { Actions, ofType } from "@ngrx/effects"
import { IUserComplex } from 'src/app/models/User.model'

@Component({
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ParentsProfileComponent implements OnDestroy {
  constructor(private _dialog: MatDialog, private _store: Store<IAppState>,
    public translate: TranslateService,
    private router: Router,
    public _authService: AuthService,
    private _actions$: Actions) { }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  private unsubscribe$ = new Subject()

  public parent$: Observable<IUserComplex> = this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentUser))

  redirectTo() {
    const externalUrl = 'https://wp.dinolingo.com/school-quote/'
    window.open(externalUrl, '_self')
  }

  openEditProfileDialog(): void {
    const dialogRef = this._dialog.open(EditProfileDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
    })

    dialogRef
      .afterClosed()
      .pipe(
        filter((user) => !!user),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((user) => {
        this._store.dispatch(new UpdateUser(user.user))
      })
  }

  /**
   * Open update avatar dialog for user
   * @sankyago
   * And then, get user object
   * @param {IThumbnail} thumbnail
   */
  openUpdateAvatarPopup() {
    const dialog = this._dialog.open(AvatarSelectPopupComponent, {
      hasBackdrop: true,
      width: '32rem',
      height: '32rem',
    })
    dialog
      .afterClosed()
      .pipe(
        filter((avatar) => avatar),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((avatar) => {
        this._store.dispatch(new UpdateUserAvatar(avatar))
      })
  }

  openChangePasswordDialog(): void {
    const dialogRef = this._dialog.open(ChangePasswordDialogComponent, {
      panelClass: 'medium-adaptive-popup',
    })
  }

  logout() {
    const dialog = this._dialog.open(ConfirmationPopupComponent, {
      panelClass: 'medium-adaptive-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: 'MODAL_CONFIRM.LOG_OUT',
    })

    dialog.afterClosed().subscribe((answer) => {
      if (!answer) return

      this._authService.logout()
      window.location.href = environment.logoutRedirectUrl
    })
  }

  addNewLanguage() {
    this.router.navigate(['/profile/parents/subusers'])
  }

  deleteUser() {
    const dialog = this._dialog.open(ConfirmationPopupComponent, {
      panelClass: 'medium-adaptive-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: 'MODAL_CONFIRM.DELETE_ACCOUNT',
    })

    dialog.afterClosed().subscribe((answer) => {
      if (!answer) return
      this._store.dispatch(new DeleteUser())

      this._actions$
        .pipe(
          ofType(EUserActions.DeleteUserSuccess, EUserActions.DeleteUserFailure),
          takeUntil(this.unsubscribe$)
        )
        .subscribe((result: Array<string> | DeleteUserSuccess | DeleteUserFailure) => {
          if (result instanceof DeleteUserSuccess) {
            this._authService.logout()
          } else if (result instanceof DeleteUserFailure) {

          }
        })
    })
  }
}
