<div class="studentAssignments" [class]="{ inDashboard: inDashboard, inLessonMap: !inDashboard }">
  <div class="actions">
    <span class="title" *ngIf="inDashboard">{{ 'CLASSROOM.ASSIGNMENTS.POPUP_TITLE' | translate }}</span>
    <span class="title" *ngIf="!inDashboard">{{ 'STUDENT.ASSIGNMENTS.POPUP_TITLE' | translate }}</span>
    <span class="close" (click)="dialogRef.close()">+</span>
  </div>

  <div class="content" *ngIf="loaded">
    <table class="assignmentsTable">
      <thead>
        <tr>
          <th>{{ 'STUDENT.ASSIGNMENTS.HEAD_LESSON' | translate }}</th>
          <th>{{ 'STUDENT.ASSIGNMENTS.HEAD_DUEDATE' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let assignment of assignments; let assignmentIndex = index">
          <td class="lessonName">
            <span *ngIf="inDashboard">{{ assignmentIndex + 1 }}. {{ assignment.lesson.unit.name }} - {{ assignment.lesson.name }}</span>
            <a *ngIf="assignment.lesson.typeId != 10 && !inDashboard" [routerLink]="['/profile/lesson/', assignment.lesson.id, assignment.lesson.type.name]" (click)="dialogRef.close()">{{ assignmentIndex + 1 }}. {{ assignment.lesson.unit.name }} - {{ assignment.lesson.name }}</a>
            <a *ngIf="assignment.lesson.typeId == 10 && !inDashboard" (click)="downloadPdf(assignment.lesson.id)">{{ assignmentIndex + 1 }}. {{ assignment.lesson.unit.name }} - {{ assignment.lesson.name }}</a>
          </td>
          <td class="dueDate">
            <span>{{ assignment.dueDate | date : 'MM/dd/yyyy' }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
