import { Injectable } from '@angular/core'
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Store, Action } from '@ngrx/store'
import { IAppState } from '../../store/state/app.state'
import { Actions, ofType } from '@ngrx/effects'
import { Observable, of } from 'rxjs'
import { take } from 'rxjs/operators'
import { GetUser, GetUserSuccess, EUserActions } from '../../store/actions/user.actions'

@Injectable()
export class UserResolver implements Resolve<Observable<Action>> {
  constructor(private store: Store<IAppState>, private action$: Actions) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Observable<Action>> {
    this.store.dispatch(new GetUser())

    return of(this.action$.pipe(ofType<GetUserSuccess>(EUserActions.GetUserSuccess), take(1)))
  }
}
