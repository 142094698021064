import { Component, Inject, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { Subject } from 'rxjs'
import { Actions, ofType } from '@ngrx/effects'
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { Store } from '@ngrx/store'
import { IAppState } from '../../../../store/state/app.state'
import { TranslateService } from '@ngx-translate/core'
import { ETeachersActions, UpdateTeacher, UpdateTeacherFailure, UpdateTeacherSuccess } from '../../../../store/actions/teachers.actions'
import { takeUntil } from 'rxjs/operators'
import { ITeacher, ITeacherRequest } from '../../../../models/Teacher.model'
import { NotificationService } from 'src/app/services/notification.service'

@Component({
  templateUrl: './edit-teacher.component.html',
  styleUrls: ['./edit-teacher.component.scss'],
})
export class EditTeacherDialogComponent implements OnInit {
  public form: UntypedFormGroup

  private destroyed$ = new Subject<boolean>()

  public unsubscribe$ = new Subject()

  constructor(
    private updates$: Actions,
    public dialogRef: MatDialogRef<EditTeacherDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public teacher: ITeacher,
    private _store: Store<IAppState>,
    public translate: TranslateService,
    private _notificationService: NotificationService,
  ) {
    this.updates$.pipe(ofType<UpdateTeacherSuccess>(ETeachersActions.UpdateTeacherSuccess), takeUntil(this.destroyed$)).subscribe((res: UpdateTeacherSuccess) => {
      this.dialogRef.close(res)
      this._notificationService.showNotification(this.translate.instant('TEACHER.EDITED'), 2)
    })

    this.updates$.pipe(ofType<UpdateTeacherFailure>(ETeachersActions.UpdateTeacherFailure), takeUntil(this.destroyed$)).subscribe((res: any) => {
      if (res.payload?.error?.clientMessage.includes('This account already exists')) {
        this.form.controls['email'].setErrors({ duplicate: res.payload.error.clientMessage })
      } else {
        this.dialogRef.close(res)
      }
    })
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      firstName: new UntypedFormControl(this.teacher.firstName, [Validators.required]),
      lastName: new UntypedFormControl(this.teacher.lastName, [Validators.required]),
      email: new UntypedFormControl(this.teacher.email, [Validators.required]),
      password: new UntypedFormControl(''),
      schoolId: new UntypedFormControl('', [Validators.required]),
    })

    this.form.patchValue({
      schoolId: this.teacher.schoolId,
    })
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

  createTeacher(form: UntypedFormGroup) {
    if (form.invalid) return
    form.controls.email.setValue(form.value.email.trim())
    const request: ITeacherRequest = form.value
    this._store.dispatch(new UpdateTeacher(this.teacher, request))
  }
}
