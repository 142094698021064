import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ChangeDetectionStrategy, OnChanges, ElementRef, ViewChild } from '@angular/core'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { ReportProblemPopupComponent } from '../../popups/Report-Dialogs/report-problem/report-problem.component'
import { IAnswer } from 'src/app/models/Answer.model'
import { IQuestion } from 'src/app/models/Question.model'
import { AudioService } from 'src/app/services/audio.service'
import { TranslateService } from '@ngx-translate/core'
import { RandomizeService } from 'src/app/services/randomize.service'
import { LocalizeHelperService } from 'src/app/services/localizHelper.service'

@Component({
  selector: 'picture-to-audio',
  templateUrl: './picture-to-audio.component.html',
  styleUrls: ['./picture-to-audio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PictureToAudioGameComponent implements OnInit, OnChanges, OnDestroy {
  @Input() question: IQuestion
  @Input() test: boolean
  @Input() game: boolean
  @Input() comparison: boolean
  @Output() success = new EventEmitter()
  @Output() failed = new EventEmitter()

  @ViewChild('audiohelp') audiohelp: ElementRef

  public currentlang

  constructor(private _dialog: MatDialog, public translate: TranslateService, private _RandomizeService: RandomizeService, private localizeHelperService: LocalizeHelperService, private audioService: AudioService) {}

  public checked = false
  public selectedAnswer: IAnswer
  public altImage = []
  public imgType
  public altAnswers = []
  public cardColors = []
  public questionId: number

  ngOnInit() {
    this.currentlang = this.localizeHelperService.getLangForced()

    this.questionId = this.question.id
    this.altImage.unshift(this._RandomizeService.getRandomQuestionImage(this.question))
    this.imgType = this.altImage[0].includes('.json') ? 'json' : ''

    for (let i = 0; i < this.question.answers.length; i++) {
      const answer = {
        audioSrc: this.question.answers[i].audioSrc,
        correct: this.question.answers[i].correct,
        id: this.question.answers[i].id,
        name: this.question.answers[i].name,
        pictureSrc: this.question.answers[i].pictureSrc,
        questionId: this.question.answers[i].questionId,
        text: this.question.answers[i].text,
      }
      this.altAnswers.push(answer)
    }

    this._RandomizeService.shuffle(this.altAnswers)

    this.cardColors = this._RandomizeService.getRandom12Colors()
  }

  /* Clear selected answer and checked answer when inputs are changed */
  ngOnChanges() {
    this.selectedAnswer = null
    this.checked = false

    if (this.altAnswers.length > 0 && this.question.id != this.questionId) {
      this.altAnswers = []
      this.createAltImagesArray(this.question)
    }
  }

  public audiohelpPlay() {
    this.audiohelp.nativeElement.play()
  }

  public createAltImagesArray(question) {
    this.altImage.unshift(this._RandomizeService.getRandomQuestionImage(question))
    this.imgType = this.altImage[0].includes('.json') ? 'json' : ''

    for (let i = 0; i < question.answers.length; i++) {
      const answer = {
        audioSrc: question.answers[i].audioSrc,
        correct: question.answers[i].correct,
        id: question.answers[i].id,
        name: question.answers[i].name,
        pictureSrc: question.answers[i].pictureSrc,
        questionId: question.answers[i].questionId,
        text: question.answers[i].text,
      }
      this.altAnswers.push(answer)
      this.audioService.preloadAudio(question.answers[i].audioSrc, question.answers[i].audioSrc)
    }

    this._RandomizeService.shuffle(this.altAnswers)

    this.cardColors = this._RandomizeService.getRandom12Colors()
  }

  ngOnDestroy() {
    this.audioService.stopAllAudio()
  }

  public selectAnswer(answer) {
    if (this.checked && this.game) return this.playAudio(answer.audioSrc)
    if (this.checked && this.test) return
    this.selectedAnswer = answer
    this.playAudio(answer.audioSrc)
  }

  public setAnswer(answer) {
    this.audioService.stopAudio(this.question.audioSrc)
    if (answer.correct) this.success.emit({ question: this.question, answer })
    else this.failed.emit({ question: this.question, answer })
  }

  public setAnswerTest(answer) {
    this.audioService.stopAudio(this.question.audioSrc)
    if (answer.correct) this.success.emit({ question: this.question, answer })
    else this.failed.emit({ question: this.question, answer })
  }

  public playAudio(src) {
    this.audioService.playAudio(src, src)
  }

  public checkAnswer() {
    this.checked = true
    if (this.selectedAnswer.correct) {
      this.audioService.playSoundCorrect()
    } else {
      this.audioService.playSoundIncorrect()
    }
  }

  public get checkRightAnswer() {
    return this.selectedAnswer.correct
  }

  public openReportPopup(question) {
    this._dialog.open(ReportProblemPopupComponent, {
      closeOnNavigation: true,
      hasBackdrop: true,
      data: question,
      panelClass: 'medium-adaptive-popup',
    })
  }
}
