import { Injectable } from '@angular/core'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { Observable, of } from 'rxjs'
import { catchError, exhaustMap, map, mergeMap } from 'rxjs/operators'
import { ITokenPair, IUser, IUserLogin } from 'src/app/models/User.model'
import { AuthService } from 'src/app/services/auth.service'
import { NotificationService } from 'src/app/services/notification.service'
import { PaymentService } from 'src/app/services/payment.service'
import { UnauthorizedComponent } from 'src/app/shared/popups/Control-Dialogs/unauthorized/unauthorized.component'
import { EAuthActions, RefreshToken, RefreshTokenFailure, SignIn, SignInFailure, SignInSuccess, SignUp, SignUpFailure, SignUpSuccess } from '../actions/auth.actions'
import { GetAllSubuser } from '../actions/subuser.actions'
import { GetUser } from '../actions/user.actions'
import { IAppState } from '../state/app.state'

@Injectable()
export class AuthEffects {
  signIn$: Observable<SignInSuccess | SignInFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<SignIn>(EAuthActions.SignIn),
      map((action: SignIn) => action.payload),
      mergeMap((payload: IUserLogin) => this._authService.signIn(payload)),
      mergeMap((tokenPair: ITokenPair) => {
        if (undefined !== tokenPair.refreshToken) {
          localStorage.setItem('refreshToken', tokenPair.refreshToken)
          localStorage.setItem('accessToken', tokenPair.accessToken)
          this._router.navigate(['/profile/lessons'])
          return of(new SignInSuccess(tokenPair))
        }
        return []
      }),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 4)
        this._store.dispatch(new SignInFailure(error.error))
        return caught
      })
    )
  )

  // @Effect() // TODO RELOAD TEST
  refreshToken$: Observable<GetAllSubuser | GetUser | RefreshTokenFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<RefreshToken>(EAuthActions.RefreshToken),
      mergeMap(() => this._authService.refreshToken()),
      mergeMap((tokenPair: ITokenPair) => {
        localStorage.setItem('accessToken', tokenPair.accessToken)
        localStorage.setItem('refreshToken', tokenPair.refreshToken)
        return [new GetUser(), new GetAllSubuser()]
      }),
      catchError((err, caught) => {
        const { error } = err
        localStorage.clear()

        this._dialog.open(UnauthorizedComponent, {
          disableClose: true,
        })
        return caught
      })
    )
  )

  signUp$: Observable<SignUpSuccess | SignUpFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<SignUp>(EAuthActions.SignUp),
      map((action: SignUp) => action.payload),
      exhaustMap((payload: IUser) =>
        this._authService.signUp(payload).pipe(
          map((tokenPair) => ({ tokenPair, payload })) // Combine tokenPair and payload into a single object
        )
      ),
      exhaustMap(({ tokenPair, payload }) => {
        localStorage.setItem('accessToken', tokenPair.accessToken)
        localStorage.setItem('refreshToken', tokenPair.refreshToken)

        this.translate.get('TOASTER_NOTIFICATIONS.SUCCESSFULLY_SIGNEDUP').subscribe((translatedText: string) => {
          this._notificationService.showNotification(translatedText, 4)
        })

        this.registrationHandler(payload, tokenPair.userId)

        return of(new SignUpSuccess(tokenPair))
      }),
      catchError((err, caught) => {
        const { error } = err
        if (error && error.clientMessage) {
          this._notificationService.showNotificationError(error.clientMessage, 4)
        }
        return caught
      })
    )
  )

  registrationHandler(payload: IUser, userId: number) {
    if (!payload.planId) {
      return this._router.navigate(['/profile/parents/subscriptions/stripe'])
    }

    if(payload.paymentMethod === 'paypal') {
      return this.createPayPalCheckoutSession(payload, userId)
    } 
    
    this.createStripeCheckoutSession(payload)
  }

  createPayPalCheckoutSession(payload: IUser, userId: number) {
    this._paymentService.createPayPalCheckoutSession({ email: payload.email, priceId: payload.planId, userId }).subscribe((session: any) => {
      this.redirectToSuccessPage(session)
    })
  }

  createStripeCheckoutSession(payload: IUser) {
    this._paymentService.createCheckoutSession({ email: payload.email, priceId: payload.planId }).subscribe((session: any) => {
      this.redirectToSuccessPage(session)
    })
  }

  redirectToSuccessPage(session: any) {
    if (session && session.url) {
      window.location.href = session.url
    }
  }

  constructor(
    private _actions$: Actions,
    private _authService: AuthService,
    private _store: Store<IAppState>,
    private _router: Router,
    private _notificationService: NotificationService,
    private _dialog: MatDialog,
    public translate: TranslateService,
    private _paymentService: PaymentService
  ) {}
}
