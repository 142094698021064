import { Component, OnInit } from '@angular/core'
import { LangChangeEvent, TranslateService } from '@ngx-translate/core'
import { Router, ActivatedRoute } from '@angular/router'
import { SEOService } from 'src/app/services/seo.service'
import { LocalizeHelperService } from 'src/app/services/localizHelper.service'

@Component({
  templateUrl: './homeschoolers.component.html',
  styleUrls: ['./homeschoolers.component.scss'],
})
export class HomeschoolersComponent implements OnInit {
  constructor(public translate: TranslateService, private router: Router, private _seoService: SEOService, private route: ActivatedRoute, private localizeHelperService: LocalizeHelperService) {}
  public currentLang: string
  public title: string
  public name: string
  public description: string
  public metaTitle = ''
  public url: string = this.router.url

  public langParam = this.route.snapshot.params && this.route.snapshot.params.lang ? this.route.snapshot.params.lang : ''

  public lURL

  ngOnInit() {
    const { meta } = this.route.snapshot.data

    this.localizeHelperService.setURLLang(this.langParam)
    this.currentLang = this.localizeHelperService.getLangForced()
    this.lURL = this.localizeHelperService.getURLLang()

    const localMeta = meta[this.currentLang] == undefined ? meta['en'] : meta[this.currentLang]

    this._seoService.updateTitle(localMeta.title)
    this._seoService.updateDescription(localMeta.description)
    this._seoService.createCanonicalLink(localMeta.canonical)
    this.localizeHelperService.createLangMetaLinks()

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this._seoService.updateTitle(meta[event.lang]['title'])
      this._seoService.updateDescription(meta[event.lang]['description'])
      this._seoService.createCanonicalLink(meta[event.lang]['canonical'])
      this.lURL = this.localizeHelperService.getURLLang()
    })


  }

  public getURLLangPart() {
    let p = this.lURL
    return p ? `/${p}` : ''
  }
}
