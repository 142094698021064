import * as _moment from 'moment'

export const sendSubuserReportOfMonth = (month, currentSubuser, date) => {
  return {
    subuserId: currentSubuser.subuserId ? currentSubuser.subuserId : currentSubuser.id,
    courseId: currentSubuser.courseId ? currentSubuser.courseId : currentSubuser.courses[0].id,
    firstDay: date[month].firstDay,
    lastDay: date[month].lastDay,
  }
}

export const generateDate = (subuserCreate, selected, resultArray) => {
  const registrationDate = new Date(subuserCreate)
  const todayDate = new Date()

  const firstDayofRegistrationMonth = new Date(registrationDate.getFullYear(), registrationDate.getMonth(), 1)
  const lastDayofRegistrationMonth = new Date(registrationDate.getFullYear(), registrationDate.getMonth() + 1, 0)

  selected = _moment(todayDate).format('MMMM YYYY')
  const firstDayOfCurrentMonth = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1)
  let nextFirstDay: Date = firstDayofRegistrationMonth
  let nextLastDay: Date = lastDayofRegistrationMonth

  resultArray = {
    [firstDayofRegistrationMonth.getTime()]: {
      firstDay: firstDayofRegistrationMonth,
      lastDay: lastDayofRegistrationMonth,
    },
  }
  while (nextFirstDay.getTime() < firstDayOfCurrentMonth.getTime()) {
    nextFirstDay = new Date(nextFirstDay.getFullYear(), nextFirstDay.getMonth() + 1, 1)
    nextLastDay = new Date(nextLastDay.getFullYear(), nextLastDay.getMonth() + 2, 0)

    resultArray[nextFirstDay.getTime()] = {
      firstDay: nextFirstDay,
      lastDay: nextLastDay,
    }
  }

  return {
    selected,
    resultArray,
    selectedKey: firstDayOfCurrentMonth.getTime()
  }
}
