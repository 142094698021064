import { Component, ViewChild, OnInit, OnDestroy, Inject } from '@angular/core'
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormGroup } from '@angular/forms'
import { SignUp, EAuthActions, SignUpSuccess } from 'src/app/store/actions/auth.actions'
import { IAppState } from 'src/app/store/state/app.state'
import { Store, select } from '@ngrx/store'
import { Actions, ofType } from '@ngrx/effects'
import { IUser } from 'src/app/models/User.model'
import { AddSubuserSuccess, ESubuserActions } from 'src/app/store/actions/subuser.actions'
import { ActivatedRoute, Router } from '@angular/router'
import { MatStepper } from '@angular/material/stepper'
import { ICourse } from 'src/app/models/Course.model'
import { GetAllCourses } from 'src/app/store/actions/courses.actions'
import { selectAllCourses } from 'src/app/store/selectors/courses.selectors'
import { DeviceDetectorService } from 'ngx-device-detector'
import { takeUntil } from 'rxjs/operators'
import { IThumbnail } from 'src/app/models/Avatar.model'
import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login'
import { SignIn } from 'src/app/store/actions/auth.actions'
import { TranslateService, LangChangeEvent } from '@ngx-translate/core'
import { Subject, Subscription } from 'rxjs'
import { GetIpUserService } from 'src/app/services/getIp.service'
import { FormatTextService } from '../../../services/formatText.service'
import { LocalizeHelperService } from 'src/app/services/localizHelper.service'
import { SEOService } from 'src/app/services/seo.service'
import { PasswordValidator } from 'src/app/helpers/validators/password.validator'
import { environment } from 'src/environments/environment'
import { DOCUMENT } from "@angular/common"

@Component({
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class AuthRegistrationComponent implements OnInit, OnDestroy {
  public formSent = false
  public timer

  public planPeriod = {
    monthly: 0,
    yearly: 1
  }

  @ViewChild('matStepper') stepper: MatStepper
  hidePassword = true
  private unsubscribe$ = new Subject()

  public courses$ = this.store.pipe(takeUntil(this.unsubscribe$), select(selectAllCourses))

  public deviceInfo = this.deviceService.getDeviceInfo()

  public signUpFormGroup: UntypedFormGroup
  public selectedUserAvatar: IThumbnail
  public selectedSubuserAvatar: IThumbnail
  public addSubuserFormGroup: UntypedFormGroup
  private user: SocialUser
  private loggedIn: boolean
  public subsriptions: Subscription = new Subscription()
  public country
  public currentLan: string
  public lURL
  public termsAccepted = false
  public redAlert = false
  public reviewsList = []

  constructor(
    private updates$: Actions,
    private fb: UntypedFormBuilder,
    private store: Store<IAppState>,
    private deviceService: DeviceDetectorService,
    private router: Router,
    private authService: SocialAuthService,
    public translate: TranslateService,
    public activeRouter: ActivatedRoute,
    private _getIpUserService: GetIpUserService,
    private textService: FormatTextService,
    private localizeHelperService: LocalizeHelperService,
    private _seoService: SEOService,
    private passwordValidator: PasswordValidator,
    @Inject(DOCUMENT) private dom,
  ) {
    this.store.dispatch(new GetAllCourses())

    this.updates$.pipe(ofType<SignUpSuccess>(EAuthActions.SignUpSuccess)).subscribe(() => {
      this.stepper.next()
    })
    
    this.updates$.pipe(ofType<AddSubuserSuccess>(ESubuserActions.AddSubuserSuccess)).subscribe(() => {
      this.router.navigate(['/profile/parents/subscriptions/stripe'])
    })

    this.translate.get('dummyTranslation').subscribe(() => {
      this.reviewsList = [
        {
          name: this.translate.instant('REGISTRATION.REVIEWS.REVIEW_1_NAME'),
          avatar: "assets/images/reviews/avatar_mom_2.svg",
          review: this.translate.instant('REGISTRATION.REVIEWS.REVIEW_1_TEXT'),
          active: true
        },
        {
          name: this.translate.instant('REGISTRATION.REVIEWS.REVIEW_2_NAME'),
          avatar: "assets/images/reviews/avatar_man_4.svg",
          review: this.translate.instant('REGISTRATION.REVIEWS.REVIEW_2_TEXT'),
          active: false
        },
        {
          name: this.translate.instant('REGISTRATION.REVIEWS.REVIEW_3_NAME'),
          avatar: "assets/images/reviews/avatar_girl_6.svg",
          review: this.translate.instant('REGISTRATION.REVIEWS.REVIEW_3_TEXT'),
          active: false
        },
        {
          name: this.translate.instant('REGISTRATION.REVIEWS.REVIEW_4_NAME'),
          avatar: "assets/images/reviews/avatar_mom.svg",
          review: this.translate.instant('REGISTRATION.REVIEWS.REVIEW_4_TEXT'),
          active: false
        },
        {
          name: this.translate.instant('REGISTRATION.REVIEWS.REVIEW_5_NAME'),
          avatar: "assets/images/reviews/avatar_man_1.svg",
          review: this.translate.instant('REGISTRATION.REVIEWS.REVIEW_5_TEXT'),
          active: false
        }
      ]
    });
  }

  public compareEmails(formGroup: FormGroup) {
    return formGroup.controls.email.value === formGroup.controls.emailConfirm.value ? false : { "notMatched": true }
  }

  public langParam = this.activeRouter.parent.snapshot.params && this.activeRouter.parent.snapshot.params.lang ? this.activeRouter.parent.snapshot.params.lang : ''

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
    this.subsriptions.unsubscribe()
    clearTimeout(this.timer)
  }

  public selectedCourse = ''
  public selectedAge = ''

  ngOnInit() {
    // GOOGLE LOGIN
    this.authService.authState.subscribe((user) => {
      const request = {
        email: user.email,
        googleTokenId: user.idToken,
        os: this.deviceInfo.os,
        browser: this.deviceInfo.browser,
        userAgent: this.deviceInfo.userAgent,
        firstName: user.firstName,
        lastName: user.lastName
      }

      this.store.dispatch(new SignIn(request))
      this.user = user
      this.loggedIn = user != null
    })
    // GOOGLE LOGIN END

    this.subsriptions.add(
      this._getIpUserService.getUserCountry().subscribe((res) => {
        this.country = res
      })
    )

    this.subsriptions.add(
      this.authService.authState.subscribe((user) => {
        this.user = user
        this.loggedIn = user != null
      })
    )

    const { meta } = this.activeRouter.snapshot.data

    this.currentLan = this.localizeHelperService.getLangForced()

    this.lURL = this.localizeHelperService.getURLLang()

    const localMeta = meta[this.currentLan] == undefined ? meta['en'] : meta[this.currentLan]

    this._seoService.updateTitle(localMeta.title)
    this._seoService.updateDescription(localMeta.description)
    this._seoService.createCanonicalLink(localMeta.canonical)
    this.localizeHelperService.createLangMetaLinks()

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.lURL = this.localizeHelperService.getURLLang()
      this.currentLan = event.lang

      this._seoService.updateTitle(localMeta.title)
      this._seoService.updateDescription(localMeta.description)
      this._seoService.createCanonicalLink(localMeta.canonical)

      this.dom.body.setAttribute('dir', this.currentLan === 'ar' ? 'rtl' : 'ltr')
    })

    this.signUpFormGroup = this.fb.group({
      avatarId: [''],
      country: [''],
      email: ['', [Validators.email, Validators.required]],
      emailConfirm: ['', [Validators.email, Validators.required]],
      password: ['', [Validators.maxLength(20), Validators.minLength(8), Validators.required, this.passwordValidator.passwordValidator]],
      pin: ['', [Validators.minLength(1), Validators.maxLength(4)]],
      os: ['', Validators.required],
      browser: ['', Validators.required],
      userAgent: ['', Validators.required],
      courseId: ['', [Validators.required]],
      ageRange: [''],
      nickname: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(10)]],
      planId: [''],
      period: ['', [Validators.required]],
      paymentMethod: ['stripe'],
      lang: ['']
    }, { validator: this.compareEmails })

    this.signUpFormGroup.patchValue({
      os: this.deviceInfo.os,
      browser: this.deviceInfo.browser,
      userAgent: this.deviceInfo.userAgent,
    })
  }

  public formatLangName(lang) {
    return this.textService.getFullLangName(lang)
  }

  public formatCourseName(course) {
    return this.textService.formatLangName(course)
  }

  public selectLanguage(language: ICourse): void {
    this.addSubuserFormGroup.patchValue({ courseId: language.id })
    this.stepper.next()
  }

  public manageTerms() {
    this.termsAccepted = !this.termsAccepted

    if (this.termsAccepted) {
      this.redAlert = false
    }
  }

  public checkTerms() {
    if (!this.termsAccepted) {
      this.redAlert = true
    }
  }

  public registerUser(requestForm: UntypedFormGroup) {
    if(requestForm.invalid) {
      return
    }

    if(!this.termsAccepted) {
      this.redAlert = true
      return
    }

    this.formSent = true
    this.timer = setTimeout(() => {
      this.formSent = false
    }, 2000)

    this.signUpFormGroup.patchValue({
      country: this.country ? this.country.country : 'USA',
      lang: this.currentLan
    })

    // check payment method
    this.setupPlan(requestForm)

    const request: IUser = requestForm.value

    this.store.dispatch(new SignUp(request))
  }

  private setupPlan(requestForm: UntypedFormGroup) {
    if (requestForm.value.paymentMethod === 'paypal') {
      return this.setPayPalPlan(requestForm)
    }

    this.setStripePlan(requestForm)
  }

  private setStripePlan(requestForm: UntypedFormGroup) {
    requestForm.value.planId = requestForm.value.period === this.planPeriod.yearly? environment.annualPlanId : environment.monthlyPlanId
  }

  private setPayPalPlan(requestForm: UntypedFormGroup) {
    requestForm.value.planId = requestForm.value.period === this.planPeriod.yearly? environment.ppAnnualPlanId : environment.ppMonthlyPlanId
  }

  public presetPlan(plan: number) {
    this.signUpFormGroup.patchValue({
      period: plan
    })
  }

  public getURLLangPart() {
    let p = this.lURL

    return p ? `/${p}` : ''
  }

  public scroll(el: HTMLElement) {
    el.scrollIntoView({behavior: 'smooth'})

    return false
  }

  public setFocus(el: HTMLElement) {
    el.focus()
  }

  public nextReview(next: boolean = true) {
    let index = this.reviewsList.findIndex((element) => element.active === true)

    index = next? index + 1 : index - 1

    if (index >= this.reviewsList.length) {
      index = 0
    } else if (index < 0) {
      index = this.reviewsList.length - 1
    }

    this.reviewsList.forEach((element) => {
      element.active = false
    })

    this.reviewsList[index].active = true
  }
}
