<section class="practice-time">
  <article class="contentWrapper" *ngIf="loaded; else elseBlock">
    <div class="column-labels">
      <div class="column-label column-label--last-date-active">{{ 'PROGRESS_REPORTS.LABELS.LAST_DATE_ACTIVE' | translate }}</div>

      <div class="column-label column-label--total-time">{{ 'PROGRESS_REPORTS.LABELS.TOTAL_TIME' | translate }}</div>
    </div>
    <div class="level">
      <div class="students" *ngFor="let log of timeLogs">
        <div class="student">
          <div class="student-avatar">
            <ng-lottie class="avatar" [options]="{ path: log.subuser.avatar.src }"> </ng-lottie>
          </div>
          <div class="student-name">{{ log.subuser.name }}</div>
        </div>

        <div class="time-logs">
          <ng-container *ngIf="log.timeLog.totalTime">
            <div class="log-row">
              <div class="left">
                <span *ngIf="!isMobileScreen">{{ log.timeLog.lastDayActive | date : 'MMM dd, yyyy' }}</span>
                <span *ngIf="isMobileScreen">{{ log.timeLog.lastDayActive | date : 'MMM dd' }}</span>
              </div>
              <div class="right">
                <div (click)="openAllPracticeTimePopup(log); $event.stopPropagation()">
                  <mat-icon color="primary">remove_red_eye</mat-icon>
                  <span class="eye-margin">Details</span>
                </div>
              </div>
              <div class="center">
                <span class="total-time">{{ formatMinutes(log.timeLog.totalTime) }}</span>
              </div>
            </div>
          </ng-container>
          <div class="log-row" *ngIf="!log.timeLog.totalTime">
            <div class="center">
              <div class="no-results">
                <span class="result-title ftsize">{{ 'LEARNING_REPORT.NO_RESULTS' | translate }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
  <ng-template #elseBlock>
    <div class="loader-back">
      <div class="loaderBlock">
        <div class="{{ loaderClass }}"></div>
      </div>
    </div>
  </ng-template>
</section>
