import { Component, OnDestroy, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { IAppState } from '../../../store/state/app.state'
import { TranslateService } from '@ngx-translate/core'
import { combineLatest, of, Subject } from 'rxjs'
import { filter, map, mergeMap, takeUntil, switchMap } from 'rxjs/operators'
import { selectCurrentUser } from '../../../store/selectors/user.selectors'
import { selectClassrooms } from '../../../store/selectors/classrooms.selectors'
import { ActivatedRoute } from '@angular/router'
import { GetAssignments } from '../../../store/actions/assignment.actions'
import { selectAssignments } from '../../../store/selectors/assignments.selectors'
import { FormatTextService } from '../../../services/formatText.service'
import { ExportReportsDialogComponent } from '../../../shared/popups/Export-Dialogs/export-reports/export-reports.component'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { PrintsService } from '../../../services/prints.service'

import { downloadPdfLink, isIpad, openPdfLink } from 'src/app/helpers/utils/LessonUtil/lessonTest.util'

import { ConfirmationPopupComponent } from 'src/app/shared/popups/Control-Dialogs/confirmation/confirmation.component'

@Component({
  templateUrl: './progress-reports.component.html',
  styleUrls: ['./progress-reports.component.scss'],
})
export class ProgressReportsComponent implements OnInit, OnDestroy {
  constructor(
    private _store: Store<IAppState>,
    public translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private textService: FormatTextService,
    private _dialog: MatDialog,
    private _printService: PrintsService
  ) { }

  private unsubscribe$ = new Subject()

  public user

  public courses
  public selectedCourse

  public classrooms = []
  public selectedClassroom

  public assignments
  public selectedAssignment

  public pdfLoading = false
  public pdfKeysAvailableLang = ['Spanish', 'French', 'German', 'Japanese', 'Korean', 'Polish', 'Portuguese - BR', 'Italian', 'English']

  public sortOptions = [
    { method: 'name_a-z', label: this.translate.instant('PROGRESS_REPORTS.SORT.BY_NAME_A-Z') },
    { method: 'name_z-a', label: this.translate.instant('PROGRESS_REPORTS.SORT.BY_NAME_Z-A') },
  ]

  public selectedSort = this.sortOptions[0]

  public type

  public loaded

  activeTab: string = 'test-results'

  activateTab(tab: string): void {
    this.activeTab = tab
  }

  ngOnInit() {
    combineLatest([this._store.select(selectCurrentUser), this.activatedRoute.data.pipe(switchMap((data) => data.courses))])
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(([user]) => user !== null),
        mergeMap(([user, courses]) => {
          this.user = user
          if (user.userType === 'parent') {
            return of({
              user,
              courses,
              classrooms: null,
              type: 'parent',
            })
          }

          if (['teacherAdmin', 'teacher'].includes(user.userType)) {
            return this._store.select(selectClassrooms).pipe(
              takeUntil(this.unsubscribe$),
              map((action) => action.classrooms),
              filter((classrooms: any) => classrooms.length),
              mergeMap((classrooms) => {
                let classroom = classrooms[0]
                this.loadAssignments(classroom.school.id, classroom.id)

                return this._store.select(selectAssignments).pipe(
                  takeUntil(this.unsubscribe$),
                  map((assignments) => {
                    return {
                      user,
                      courses,
                      classrooms,
                      assignments,
                      type: 'school',
                    }
                  })
                )
              })
            )
          }
        })
      )
      .subscribe((res: any) => {
        this.user = res.user
        this.courses = res.courses
        this.type = res.type

        this.selectedCourse = res.courses[0]

        if (res.type === 'school') {
          this.classrooms = res.classrooms
          this.assignments = res.assignments

          if (!this.selectedClassroom) {
            this.selectedClassroom = this.classroomsOptions[0]
          }
        }

        this.loaded = true
      })
  }

  loadAssignments(schoolId, classroomId) {
    this._store.dispatch(new GetAssignments(schoolId, classroomId))
  }

  public formatLangName(course) {
    return this.textService.formatLangName(course)
  }

  public getPDFKeywords() {
    const dialog = this._dialog.open(ConfirmationPopupComponent, {
      panelClass: 'medium-adaptive-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: 'MODAL_CONFIRM.DOWNLOAD_HEAVY_PDF',
    })

    dialog.afterClosed().subscribe((answer) => {
      if (!answer) return
      let slug = `printed/${this.selectedCourse.name.replace(/\s/g, '')}/Worksheets/${this.selectedCourse.name.toUpperCase().replace(/\s/g, '')}-ANSWER-KEY-1.pdf`
      this.pdfLoading = true
      this._printService.getPrintPDF(slug).subscribe((pdf) => {
        downloadPdfLink(pdf, `${this.selectedCourse.name} Worksheets - Answer Keys.pdf`, false, isIpad())
        this.pdfLoading = false
      })
    })
  }

  selectCourse(course) {
    this.selectedCourse = course

    if (this.type === 'school') {
      if (this.classroomsOptions.length) {
        this.selectClassroom(this.classroomsOptions[0])
      }
    }
  }

  selectClassroom(classroom) {
    this.selectedClassroom = classroom
    this.loadAssignments(classroom.school.id, classroom.id)
  }

  selectSort(sort) {
    this.selectedSort = sort
  }

  selectAssignment(assignment) {
    this.selectedAssignment = assignment
  }

  get classroomsOptions() {
    if (this.type === 'school') {
      return this.classrooms.filter((classroom) => {
        return classroom.courseId === this.selectedCourse.id
      })
    }

    return this.classrooms
  }

  get displayTabs() {
    if (this.type === 'parent' && this.loaded === true) {
      return true
    }

    if (this.type === 'school') {
      return this.loaded === true && this.classroomsOptions.length
    }

    return false
  }

  public openExportDialog() {
    this._dialog.open(ExportReportsDialogComponent, {
      disableClose: false,
      hasBackdrop: true,
      width: '28rem',
      data: {
        classroom: this.selectedClassroom,
      },
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }
}
