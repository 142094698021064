<header class="nav-header {{ hclass }}" *ngIf="displayHeader$" #mainHeader [ngClass]="{ opened: mobileMenuOpened }">
  <article class="contentWrapper">
    <nav class="menuBlock">
      <div class="logo">
        <img appSounds class="logo" src="/assets/images/logos/logo-wy.svg" [routerLink]="['/profile/lessons']"
          alt="DinoLingo Logo" />
      </div>
      <div class="burgerMenu">
        <div class="inner">
          <input appSounds type="checkbox" name="" id="burgerToggle" #burgerToggle (change)="setMunuStatus($event)" />
          <label for="burgerToggle"><span></span></label>

          <div class="overlay" (click)="burgerToggle.checked = false"></div>

          <nav class="burgerMenu__content burger-menu">
            <button appSounds (click)="openCurriculumDialog()">{{ 'LINKS.LESSON_PLAN' | translate }}</button>
            <button *ngIf="type === 'student'" appSounds (click)="openStudentAssignmentsDialog()">{{
              'HEADER.ASSIGNMENTS' | translate }} ({{ student.incompleteAssignments }})</button>
            <button *ngIf="userType != 'student'" appSounds (click)="profileLink('/profile/parents/subusers')">{{
              'LINKS.DASHBOARD' | translate }}</button>
            <button *ngIf="userType != 'student'" appSounds (click)="openSelectUserPopup()">{{ 'BUGER_MENU.CHILD' |
              translate }}</button>
            <button *ngIf="userType != 'student'" appSounds (click)="openSelectCoursePopup()">{{ 'BUGER_MENU.COURSE' |
              translate }}</button>
            <button appSounds (click)="stars.emit(true)">{{ 'BUGER_MENU.COINS' | translate }}</button>
            <button appSounds (click)="dinosaurs.emit(true)">{{ 'BUGER_MENU.DINO' | translate }}</button>
            <button appSounds (click)="achievements.emit(true)">{{ 'BUGER_MENU.ACHIEVE' | translate }}</button>
            <button appSounds (click)="logout()">{{ 'BUTTONS.LOGOUT' | translate }}</button>
          </nav>
        </div>
      </div>
      <div class="cont">
        <div class="courseMenu">
          <div class="course" (click)="openSelectCoursePopup()">
            <button appSounds mat-button>
              <img src="/assets/images/languages/{{ (course$ | async)?.slug }}.png"
                alt="selected-course-{{ (course$ | async)?.slug }}" />
              <span>{{ formatLangName(course$ | async) }}</span>
              <img class="arrow" src="/assets/images/icons/arr-down.svg" alt="dropdown" />
            </button>
          </div>
          <div class="no-click">
            <a appSounds (click)="openCurriculumDialog()">
              <span>{{ 'HEADER.LESSON_PLAN' | translate }} </span>
              <img src="/assets/images/icons/report-btn-blue.svg" class="mat-icon" alt="progress-icon" />
              <span>{{ (statistics$ | async)?.progress }}%</span>
              <img class="arrow" src="/assets/images/icons/arr-down.svg" alt="dropdown" />
            </a>
          </div>
        </div>
        <div class="assignmentsMenu" *ngIf="type === 'student'">
          <button appSounds mat-button (click)="openStudentAssignmentsDialog()">
            <span>{{ 'HEADER.ASSIGNMENTS' | translate }} ({{ student.incompleteAssignments }})</span>
          </button>
        </div>
      </div>
    </nav>

    <div class="achievementsBlock">
      <div class="achievements">
        <div class="achievement" appSounds (click)="stars.emit(true)">
          <img class="star-icon" src="/assets/images/icons/coin.svg" alt="coinsIcon" />
          <span *ngIf="(statistics$ | async)?.starsCount == null">0</span>
          <span>{{ (statistics$ | async)?.starsCount }}</span>
        </div>
        <div appSounds class="achievement" (click)="dinosaurs.emit(true)">
          <img src="/assets/images/icons/dinoIcon.svg" alt="dinoIcon" />
          <span *ngIf="(statistics$ | async)?.dinosaursCount == null">0</span>
          <span>{{ (statistics$ | async)?.dinosaursCount }}</span>
        </div>
        <div appSounds class="achievement" (click)="achievements.emit(true)">
          <img src="/assets/images/icons/achieve.svg" alt="achieveIcon" />
          <span *ngIf="(statistics$ | async).achievementsCount == null">0</span>
          <span>{{ (statistics$ | async)?.achievementsCount }}</span>
        </div>
        <div appSounds *ngIf="!subuserDropdown" class="avatarUser"
          (click)="userType != 'student' && openSelectUserPopup()">
          <ng-container *ngIf="subuser$?.avatar">
            <ng-lottie *ngFor="let ava of avatars" class="ava" style="width: 75px; height: 75px"
              [options]="{ path: ava }"> </ng-lottie>
          </ng-container>

          <span>{{ subuser$?.name }}</span>
        </div>
        <div *ngIf="subuserDropdown" class="subuserDropdown">
          <ng-container *ngFor="let subuser of subusers$">
            <ng-lottie (click)="selectSubuser(subuser)" style="width: 100%; height: 100%"
              [options]="{ path: subuser?.avatar.src }"> </ng-lottie>
          </ng-container>
        </div>
        <div *ngIf="subusers$ && subusers$.length > 1" class="avatarDDown" (click)="openSubuserDropdown($event)">
          <img src="/assets/images/icons/arr-down.svg" alt="dropdown" />
        </div>
      </div>
      <div appSounds class="dashboard">
        <div class="img-container">
          <img class="profile-icon" src="/assets/images/icons/dinosaur-dashboard.svg" alt="Parent dashboard"
            (click)="openProfileMenu($event)" />
        </div>
        <span>
          <p>Dashboard</p>
        </span>
        <nav class="profile-menu" *ngIf="profileMenu">
          <button appSounds
            (click)="profileLink(userType !== 'student' ? '/profile/parents/subusers' : '/profile/parents/gradebook')">{{
            'LINKS.DASHBOARD' | translate }}</button>
          <button *ngIf="userType != 'student'" appSounds (click)="profileLink('/profile/lesson-plan')">{{
            'LINKS.PRINTABLES' | translate }}</button>
          <button appSounds (click)="logout()">{{ 'BUTTONS.LOGOUT' | translate }}</button>
        </nav>
      </div>
    </div>
  </article>
  <div class="unit-name" *ngIf="currentCourse">
    <span>{{ courseName }}</span>
  </div>

  <div appSounds class="bugReports" (click)="openReportPopup()" *ngIf="!_user?.studentCode">
    <img src="/assets/images/lessons/items/report_mobile.png" alt="Bug report" />
  </div>
</header>