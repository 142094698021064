import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog'
import { ISubuser } from 'src/app/models/Subuser.model'
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms'
import { Store, select } from '@ngrx/store'
import { IAppState } from 'src/app/store/state/app.state'
import { Subject, Observable } from 'rxjs'
import { SelectSubuser, GetSubuser, UpdateSubuser, UpdateSubuserSuccess, ESubuserActions, RemoveSubuser, RemoveSubuserSuccess } from 'src/app/store/actions/subuser.actions'
import { selectCurrentUser } from 'src/app/store/selectors/user.selectors'
import { Actions, ofType } from '@ngrx/effects'
import { takeUntil } from 'rxjs/operators'
import { Router } from '@angular/router'
import { selectAllSubuser } from 'src/app/store/selectors/subuser.selectors'
import { TranslateService } from '@ngx-translate/core'
import { NotificationService } from 'src/app/services/notification.service'
import { ConfirmationPopupComponent } from '../../Control-Dialogs/confirmation/confirmation.component'
import { SubUserStoreService } from "src/app/store/services/subuser-store.service"

@Component({
  templateUrl: './editSubuser.component.html',
  styleUrls: ['./editSubuser.component.scss'],
})
export class EditSubuserDialogComponent implements OnInit, OnDestroy {
  public user: any
  public editSubuserForm
  private destroyed$ = new Subject<boolean>()
  private unsubscribe$ = new Subject()
  public subuser$ = this._store.pipe(takeUntil(this.unsubscribe$), select(selectAllSubuser))

  constructor(
    public dialogRef: MatDialogRef<EditSubuserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public subuser: ISubuser,
    private _store: Store<IAppState>,
    private updates$: Actions,
    private _dialog: MatDialog,
    private router: Router,
    public translate: TranslateService,
    private _notificationService: NotificationService,
    public subUserStoreService: SubUserStoreService
  ) {
    this.updates$.pipe(ofType<UpdateSubuserSuccess>(ESubuserActions.UpdateSubuserSuccess), takeUntil(this.destroyed$)).subscribe((res: UpdateSubuserSuccess) => {
      this.dialogRef.close(true)
      this.translate.get('TOASTER_NOTIFICATIONS.USER_UPDATED_SUCCESSFULLY').subscribe((translatedText: string) => {
        this._notificationService.showNotification(translatedText, 2)
      })
    })

    this.updates$.pipe(ofType<RemoveSubuserSuccess>(ESubuserActions.RemoveSubuserSuccess), takeUntil(this.destroyed$)).subscribe((res: RemoveSubuserSuccess) => {
      this.dialogRef.close(false)
      this.router.navigate(['/profile/parents/subusers'])
      this.translate.get('TOASTER_NOTIFICATIONS.USER_DELETED_SUCCESSFULLY').subscribe((translatedText: string) => {
        this._notificationService.showNotification(translatedText, 2)
      })
    })
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  ngOnInit() {
    this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentUser)).subscribe((user) => {
      this.user = user
    })

    this.editSubuserForm = new UntypedFormGroup({
      name: new UntypedFormControl(this.subuser.name, [Validators.required, Validators.minLength(3), Validators.maxLength(10)]),
      age: new UntypedFormControl(this.subuser.age, [Validators.required]),
    })
  }

  updateSubuser() {
    const request = {
      id: this.subuser.id,
      name: this.editSubuserForm.value.name,
      age: this.editSubuserForm.value.age,
    }

    this._store.dispatch(new UpdateSubuser(request))
  }

  removeSubuser() {
    const dialog = this._dialog.open(ConfirmationPopupComponent, {
      panelClass: 'medium-adaptive-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: 'MODAL_CONFIRM.REMOVE_CHILD',
    })

    dialog.afterClosed().subscribe((answer) => {
      if (!answer) return

      delete this.subUserStoreService.subuserCurrentCourse[`${this.subuser.id}`]
      delete this.subUserStoreService.currentSubuser
      this._store.dispatch(new RemoveSubuser(this.subuser.id))
      // Select another subuser then
      const nextActiveUser = this.user.user.subusers[0]
      this.subUserStoreService.currentSubuser = JSON.stringify(nextActiveUser)
      this._store.dispatch(new SelectSubuser(nextActiveUser))
      this._store.dispatch(new GetSubuser(nextActiveUser.id))
      this.dialogRef.close(false)
    })
  }

  onNoClick(): void {
    this.dialogRef.close()
  }
}
