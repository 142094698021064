import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router'
import { Action, Store } from '@ngrx/store'
import { IAppState } from '../../store/state/app.state'
import { Actions, ofType } from '@ngrx/effects'
import { Observable, of } from 'rxjs'
import { ESubuserActions, GetAllSubuserCourses, GetAllSubuserCoursesSuccess } from '../../store/actions/subuser.actions'
import { filter, map, take } from 'rxjs/operators'

@Injectable()
export class CoursesResolver implements Resolve<Observable<Action>> {
  constructor(private _store: Store<IAppState>, private _action$: Actions) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Observable<Action>> {
    this._store.dispatch(new GetAllSubuserCourses())
    return of(this._action$.pipe(
      ofType<GetAllSubuserCoursesSuccess>(ESubuserActions.GetAllSubuserCoursesSuccess),
      take(1),
      filter((res) => !!res.payload),
      map((res: GetAllSubuserCoursesSuccess) => res.payload)
    ))
  }
}
