import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ChangeDetectionStrategy, OnChanges, ElementRef, ViewChild, NgZone } from '@angular/core'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { ReportProblemPopupComponent } from '../../popups/Report-Dialogs/report-problem/report-problem.component'
import { AudioService } from 'src/app/services/audio.service'
import { IAnswer } from 'src/app/models/Answer.model'
import { IQuestion } from 'src/app/models/Question.model'
import { TranslateService } from '@ngx-translate/core'
import { RandomizeService } from 'src/app/services/randomize.service'
import { LocalizeHelperService } from 'src/app/services/localizHelper.service'
import { AnimationItem } from 'lottie-web'

@Component({
  selector: 'click-and-tell',
  templateUrl: './click-and-tell.component.html',
  styleUrls: ['./click-and-tell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClickAndTellGameComponent implements OnInit, OnChanges, OnDestroy {
  @Input() question: IQuestion
  @Input() test: boolean
  @Input() game: boolean
  @Output() success = new EventEmitter()
  @Output() failed = new EventEmitter()
  @Input() comparison: boolean

  public listened: Array<IAnswer> = []
  public altAnswers = []
  public questionId: number
  public cardColors = []

  public animationItem: AnimationItem[] = []

  @ViewChild('audiohelp') audiohelp: ElementRef

  public currentlang

  constructor(private _dialog: MatDialog, public translate: TranslateService, private _RandomizeService: RandomizeService, private localizeHelperService: LocalizeHelperService, private audioService: AudioService, private ngZone: NgZone) {}

  ngOnInit() {
    this.questionId = this.question.id
    this.createNewAnswersArray(this.question)
    this.cardColors = this._RandomizeService.getRandom12Colors()
    this.currentlang = this.localizeHelperService.getLangForced()
  }

  /* Clear selected answer and checked answer when inputs are changed */
  ngOnChanges() {
    this.listened = []

    if (this.altAnswers.length > 0 && this.question.id != this.questionId) {
      this.altAnswers = []
      this.createNewAnswersArray(this.question)
    }
  }

  ngOnDestroy() {
    this.audioService.stopAllAudio()
  }

  public audiohelpPlay() {
    this.audiohelp.nativeElement.play()
  }

  public lottieFrameChanged(el) {
    if (el.detail.frame > 60) {
      el.target.pause()
    }
  }

  public createNewAnswersArray(question) {
    for (let i = 0; i < question.answers.length; i++) {
      const altImage = this._RandomizeService.getRandomAnswerImage(question.answers[i])
      const answer = {
        audioSrc: question.answers[i].audioSrc,
        correct: question.answers[i].correct,
        id: question.answers[i].id,
        name: question.answers[i].name,
        pictureSrc: altImage,
        imgType: altImage.includes('.json') ? 'json' : '',
        questionId: question.answers[i].questionId,
        text: question.answers[i].text,
      }
      this.altAnswers.push(answer)
      this.audioService.preloadAudio(question.answers[i].audioSrc, question.answers[i].audioSrc)
    }
  }

  public clickAndTell(card: IAnswer) {
    if (!this.listened.includes(card)) this.listened.push(card)
    this.audioService.playAudio(card.audioSrc, card.audioSrc)
  }

  public setAnswer(question: IQuestion) {
    this.success.emit(question)
  }

  public get checkAllListened() {
    return this.listened.length === this.question.answers.length
  }

  public openReportPopup(question: IQuestion) {
    this._dialog.open(ReportProblemPopupComponent, {
      closeOnNavigation: true,
      hasBackdrop: true,
      data: question,
      panelClass: 'medium-adaptive-popup',
    })
  }

  public playAnim(index) {
    this.ngZone.runOutsideAngular(() => {
      this.animationItem[index].play()
    })
  }

  public stopAnim(index) {
    this.ngZone.runOutsideAngular(() => {
      this.animationItem[index].pause()
    })
  }

  public animationCreated(animationItem: AnimationItem, index): void {
    this.animationItem[index] = animationItem
  }
}
