import * as FileSaver from 'file-saver'

const isIpad = (): boolean => {
  return !!window['GestureEvent'] || /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

const createFileUrl = (pdf, octetStream = false) => {
  const linkSource = pdf.split(',')

  const byteCharacters = atob(linkSource[1])
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }

  const byteArray = new Uint8Array(byteNumbers)
  const file = new Blob([byteArray], { type: `application/${octetStream ? 'octet-stream' : 'pdf'};base64` })
  return URL.createObjectURL(file)
}

const openPdfLink = (pdf) => {
  const fileURL = createFileUrl(pdf);
  if (isIpad()) {
    return FileSaver.saveAs(fileURL)
  }

  window.open(fileURL)
}

const downloadIPadPdfLink = (pdf, name) => {
  const fileURL = createFileUrl(pdf, true)

  FileSaver.saveAs(fileURL, name)
}

const downloadPdfLink = (pdf, name, path = false, isIpad = false) => {
  if (isIpad) {
    return downloadIPadPdfLink(pdf, name);
  }

  const linkSource = pdf
  const downloadLink = document.createElement('a')

  const fileName = path ? `${name}-Test.pdf` : `${name}`

  downloadLink.href = linkSource
  downloadLink.download = fileName
  downloadLink.click()
  downloadLink.remove()
}

export { openPdfLink, downloadPdfLink, isIpad }
