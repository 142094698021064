<section class="subscriptionsWrapper">
  <div class="container">
    <div class="subscriptions">
      <article class="contentWrapper">
        <div class="trial-status">
          <button class="button-red" *ngIf="anyActualSubscription() === false && isTrialActive() === false">Trial
            Expired</button>
          <button class="button-green" *ngIf="isTrialActive()">Trial Active</button>
        </div>
        <div *ngIf="!actualSubscriptions && !isPending()" class="plans-container">
          <div class="plans">
            <div class="headline">
              <h2 [innerHTML]="'SUBSCRIPTIONS.ENTER_PAYMENT' | translate"></h2>
              <p [innerHTML]="'SUBSCRIPTIONS.ENTER_PAYMENT_SUBTEXT' | translate"></p>
            </div>

            <div class="row">
              <div class="col justify-center">
                <div class="plan-box" (click)="checkoutSession('monthly')">
                  <h2>{{ 'SUBSCRIPTIONS.STRIPE_TITLE' | translate }}</h2>
                  <div class="chip">Most Popular</div>
                  <div class="sub-chip">&nbsp;</div>
                  <h1>$19</h1>
                  <p>{{ 'SUBSCRIPTIONS.STRIPE_TITLE' | translate }}</p>
                  <button mat-button color="accent">Select</button>
                </div>
              </div>

              <div class="col justify-center">
                <div class="plan-box" (click)="checkoutSession('yearly')">
                  <h2>{{ 'SUBSCRIPTIONS.PAYPAL_TITLE' | translate }}</h2>
                  <div class="chip">Best Value</div>
                  <div class="sub-chip">Save %30</div>
                  <h1>$199</h1>
                  <p>{{ 'SUBSCRIPTIONS.PAYPAL_TITLE' | translate }}</p>
                  <button mat-button color="accent">Select</button>
                </div>
              </div>
            </div>
          </div>
          <div class="payment-options">
            <p>{{ 'SUBSCRIPTIONS.PAYMENT_OPTIONS_INFO' | translate }}</p>
            <mat-radio-group class="radio-group" aria-label="Select an option" [(ngModel)]="selectedMethod">
              <mat-radio-button value="stripe"><img src="assets/images/icons/credit-card.png"
                  alt="Stripe" /></mat-radio-button>
              <mat-radio-button value="paypal"><img src="assets/images/logos/paypal-logo.png"
                  alt="PayPal" /></mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="info">
            <p>{{ 'SUBSCRIPTIONS.INFO_TEXT_1' | translate }}</p>
          </div>
        </div>

        <div class="active-subscription" *ngIf="actualSubscriptions">
          <h1 class="already-active">You already have active subscription</h1>
          <button mat-raised-button color="accent" (click)="manageSubscription()">Manage Subscriptions</button>
        </div>

        <div class="pending-subscription" *ngIf="isPending()">
          <h1 class="already-active">Your subscription is pending payment</h1>
          <button mat-raised-button color="accent" (click)="manageSubscription()">Manage Subscriptions</button>
        </div>

        <div class="links">
          <a target="_blank" href="https://buy.stripe.com/00gfZB64o7CrfQY8xG">{{ 'SUBSCRIPTIONS.LINK_1' | translate
            }}</a>
          <a href="https://wp.dinolingo.com/school-quote/" target="_blank">{{ 'SUBSCRIPTIONS.LINK_2' | translate
            }}</a>
        </div>
      </article>
      <div class="dino-decor">
        <img src="assets/images/login/dino-plans.svg" alt=" " />
      </div>
    </div>

    <div class="secondBlock">
      <article class="faqBlock">
        <h2 class="baloo">{{ 'SUBSCRIPTIONS.FAQ.TITLE' | translate }}</h2>
        <div class="content">
          <mat-accordion>
            <mat-expansion-panel hideToggle class="mat-elevation-z">
              <mat-expansion-panel-header>
                <mat-panel-title class="textCenter">
                  <div class="icon"></div>
                  <h4>
                    {{ 'SUBSCRIPTIONS.FAQ.QUESTION_2' | translate }}
                  </h4>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="content">
                <p>{{ 'SUBSCRIPTIONS.FAQ.ANSWER_2' | translate }}</p>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel hideToggle class="mat-elevation-z">
              <mat-expansion-panel-header>
                <mat-panel-title class="textCenter">
                  <div class="icon"></div>
                  <h4>
                    {{ 'SUBSCRIPTIONS.FAQ.QUESTION_3' | translate }}
                  </h4>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="content">
                <p [innerHTML]="'SUBSCRIPTIONS.FAQ.ANSWER_3' | translate"></p>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel hideToggle class="mat-elevation-z">
              <mat-expansion-panel-header>
                <mat-panel-title class="textCenter">
                  <div class="icon"></div>
                  <h4>
                    {{ 'SUBSCRIPTIONS.FAQ.QUESTION_4' | translate }}
                  </h4>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="content">
                <p>{{ 'SUBSCRIPTIONS.FAQ.ANSWER_4' | translate }}</p>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel hideToggle class="mat-elevation-z">
              <mat-expansion-panel-header>
                <mat-panel-title class="textCenter">
                  <div class="icon"></div>
                  <h4>
                    {{ 'SUBSCRIPTIONS.FAQ.QUESTION_5' | translate }}
                  </h4>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="content">
                <p [innerHTML]="'SUBSCRIPTIONS.FAQ.ANSWER_5' | translate"></p>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel hideToggle class="mat-elevation-z">
              <mat-expansion-panel-header>
                <mat-panel-title class="textCenter">
                  <div class="icon"></div>
                  <h4>
                    {{ 'SUBSCRIPTIONS.FAQ.QUESTION_6' | translate }}
                  </h4>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="content">
                <p [innerHTML]="'SUBSCRIPTIONS.FAQ.ANSWER_6' | translate"></p>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </article>
    </div>

    <div class="reviews-section-container">
      <div class="reviews-section">
        <div class="heading">{{ 'HOME.BLOCK_2.TEXT' | translate }}</div>
        <div class="review-container">
          <div class="review-inner">
            <!-- Review Content -->
            <div class="review-content">
              <div class="reviewer-container">
                <div class="reviewer-img-container">
                  <img src="assets/images/testimonials/autumn.jpg" />
                </div>
                <div class="reviewer-name">Autumn E.</div>
              </div>
              <div class="stars-container">
                <div class="star-container">
                  <img src="assets/images/reviews/star.png" />
                </div>
                <div class="star-container">
                  <img src="assets/images/reviews/star.png" />
                </div>
                <div class="star-container">
                  <img src="assets/images/reviews/star.png" />
                </div>
                <div class="star-container">
                  <img src="assets/images/reviews/star.png" />
                </div>
                <div class="star-container">
                  <img src="assets/images/reviews/star.png" />
                </div>
              </div>
              <div class="review-text">
                {{ 'HOME.BLOCK_5.REVIEW_1' | translate }}
              </div>
            </div>
          </div>

          <div class="review-inner">
            <!-- Review Content -->
            <div class="review-content">
              <div class="reviewer-container">
                <div class="reviewer-img-container">
                  <img src="assets/images/testimonials/rivas.jpg" />
                </div>
                <div class="reviewer-name">Paula C.</div>
              </div>
              <div class="stars-container">
                <div class="star-container">
                  <img src="assets/images/reviews/star.png" />
                </div>
                <div class="star-container">
                  <img src="assets/images/reviews/star.png" />
                </div>
                <div class="star-container">
                  <img src="assets/images/reviews/star.png" />
                </div>
                <div class="star-container">
                  <img src="assets/images/reviews/star.png" />
                </div>
                <div class="star-container">
                  <img src="assets/images/reviews/star.png" />
                </div>
              </div>
              <div class="review-text">
                {{ 'HOME.BLOCK_5.REVIEW_4' | translate }}
              </div>
            </div>
          </div>

          <div class="review-inner">
            <!-- Review Content -->
            <div class="review-content">
              <div class="reviewer-container">
                <div class="reviewer-img-container">
                  <img src="assets/images/testimonials/brittney.jpg" />
                </div>
                <div class="reviewer-name">Britnee S.</div>
              </div>
              <div class="stars-container">
                <div class="star-container">
                  <img src="assets/images/reviews/star.png" />
                </div>
                <div class="star-container">
                  <img src="assets/images/reviews/star.png" />
                </div>
                <div class="star-container">
                  <img src="assets/images/reviews/star.png" />
                </div>
                <div class="star-container">
                  <img src="assets/images/reviews/star.png" />
                </div>
                <div class="star-container">
                  <img src="assets/images/reviews/star.png" />
                </div>
              </div>
              <div class="review-text">
                {{ 'HOME.BLOCK_5.REVIEW_5' | translate }}
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>