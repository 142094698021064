import { Component, OnInit, ViewChild, Inject } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms'
import { MatStepper } from '@angular/material/stepper'
import { AuthService } from 'src/app/services/auth.service'
import { TranslateService, LangChangeEvent } from '@ngx-translate/core'
import { NotificationService } from 'src/app/services/notification.service'
import { FormatTextService } from '../../../services/formatText.service'
import { LocalizeHelperService } from 'src/app/services/localizHelper.service'
import { ActivatedRoute } from '@angular/router'
import { SEOService } from 'src/app/services/seo.service'
import { PasswordValidator } from 'src/app/helpers/validators/password.validator'

@Component({
  templateUrl: './restore-password.component.html',
  styleUrls: ['./restore-password.component.scss'],
})
export class RestorePasswordComponent implements OnInit {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private _authService: AuthService,
    public translate: TranslateService,
    private _notifications: NotificationService,
    private textService: FormatTextService,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private dom,
    private localizeHelperService: LocalizeHelperService,
    private _seoService: SEOService,
    private passwordValidator: PasswordValidator
  ) {}
  public restoreForm: UntypedFormGroup
  public codeForm: UntypedFormGroup
  public currentLan: string

  public langParam = this.route.parent.snapshot.params && this.route.parent.snapshot.params.lang ? this.route.parent.snapshot.params.lang : ''

  public lURL

  ngOnInit() {
    const { meta } = this.route.snapshot.data

    this.currentLan = this.localizeHelperService.getLangForced()

    this.lURL = this.localizeHelperService.getURLLang()

    const localMeta = meta[this.currentLan] == undefined ? meta['en'] : meta[this.currentLan]

    this._seoService.updateTitle(localMeta.title)
    this._seoService.updateDescription(localMeta.description)
    this._seoService.createCanonicalLink(localMeta.canonical)
    this.localizeHelperService.createLangMetaLinks()

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.lURL = this.localizeHelperService.getURLLang()
      this._seoService.updateTitle(localMeta.title)
      this._seoService.updateDescription(localMeta.description)
      this._seoService.createCanonicalLink(localMeta.canonical)
      this.localizeHelperService.createLangMetaLinks()
    })

    this.restoreForm = this.formBuilder.group({
      email: ['', [Validators.email, Validators.required]],
      lang: ['en'],
    })
    this.codeForm = this.formBuilder.group({
      code: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern('^[0-9]*$')]],
      newPassword: ['', [Validators.maxLength(20), Validators.minLength(8), Validators.required, this.passwordValidator.passwordValidator]],
    })
  }

  @ViewChild('stepper', { static: false }) stepper: MatStepper

  onSubmit() {
    if (!this.restoreForm.valid) return

    this.restoreForm.patchValue({ lang: this.currentLan })

    this._authService
      .restorePass(this.restoreForm.value)
      .toPromise()
      .then(() => this.stepper.next())
      .catch((error) => this._notifications.showNotification(error.error.clientMessage, 4))
  }

  public formatLangName(lang) {
    return this.textService.getFullLangName(lang)
  }

  /*
   *if there is an error - clears the fields and displays a notification about what exactly the error occurred
   */
  setNewPassword() {
    if (!this.codeForm.valid) return
    const request = {
      resetCode: this.codeForm.value.code,
      newPassword: this.codeForm.value.newPassword,
      email: this.restoreForm.value.email,
      lang: this.currentLan,
    }
    this._authService
      .checkPassword(request)
      .toPromise()
      .then((res) => {
        this.stepper.next()
      })
      .catch((error) => {
        this._notifications.showNotification(error.error.clientMessage, 4)
        this.clearRestorPassword()
      })
  }

  public clearRestorPassword() {
    this.stepper.selectedIndex = 0
    this.restoreForm.reset()
    this.codeForm.reset()
  }

  public getURLLangPart() {
    let p = this.lURL
    return p ? `/${p}` : ''
  }
}
