import { Component } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'

@Component({
  selector: 'app-message',
  templateUrl: 'app-message.component.html',
  styleUrls: ['app-message.component.scss'],
})
export class AppMessageComponent {
  constructor(public dialogRef: MatDialogRef<AppMessageComponent>) {}

  close() {
    this.dialogRef.close()
  }
}
