import { Component, OnDestroy, OnInit } from '@angular/core'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { Store, select } from '@ngrx/store'
import { IAppState } from 'src/app/store/state/app.state'
import { ISubuser } from 'src/app/models/Subuser.model'
import { selectAllSubuser } from 'src/app/store/selectors/subuser.selectors'
import { AddSubuserSuccess, GetAllSubuser } from 'src/app/store/actions/subuser.actions'
import { Observable, Subject } from 'rxjs'
import { selectCurrentUser } from 'src/app/store/selectors/user.selectors'
import { IUser } from 'src/app/models/User.model'
import { TranslateService } from '@ngx-translate/core'
import { takeUntil } from 'rxjs/operators'
import { AddSubuserDialogComponent } from 'src/app/shared/popups/Subuser-Dialogs/add-subuser/addSubuser.component'
import { LessonSubscribePopupComponent } from 'src/app/shared/popups/Lesson-Dialogs/lesson-subscribe/lesson-subscribe.component'
import { Router } from '@angular/router'
import { ConfirmationPopupComponent } from 'src/app/shared/popups/Control-Dialogs/confirmation/confirmation.component'
import { Actions, ofType } from '@ngrx/effects'
import { SelectSubuser, GetSubuser, ESubuserActions, RemoveSubuser, RemoveSubuserSuccess } from 'src/app/store/actions/subuser.actions'
import { NotificationService } from 'src/app/services/notification.service'
import { SubUserStoreService } from "src/app/store/services/subuser-store.service"

@Component({
  templateUrl: './subusers.component.html',
  styleUrls: ['./subusers.component.scss'],
})
export class ParentsSubusersComponent implements OnInit, OnDestroy {
  public showAddSubuserButton = true

  private unsubscribe$ = new Subject()

  public subuser$: Observable<Array<ISubuser>> = this._store.pipe(select(selectAllSubuser))
  public user$: Observable<IUser> = this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentUser))
  public user: any
  public editSubuserForm
  private destroyed$ = new Subject<boolean>()
  constructor(
    private _dialog: MatDialog,

    private _store: Store<IAppState>,
    public translate: TranslateService,
    private updates$: Actions,
    public _notificationService: NotificationService,
    private router: Router,
    public subUserStoreService: SubUserStoreService
  ) {
    this.updates$.pipe(ofType<AddSubuserSuccess>(ESubuserActions.AddSubuserSuccess), takeUntil(this.destroyed$)).subscribe((res: AddSubuserSuccess) => {
      this.router.navigate(['/profile/parents/subusers'])

      this.translate.get('TOASTER_NOTIFICATIONS.USER_ADDED_SUCCESSFULLY').subscribe((translatedText: string) => {
        this._notificationService.showNotification(translatedText, 2)
      })
    })

    this.updates$.pipe(ofType<RemoveSubuserSuccess>(ESubuserActions.RemoveSubuserSuccess), takeUntil(this.destroyed$)).subscribe((res: RemoveSubuserSuccess) => {
      this.router.navigate(['/profile/parents/subusers'])
      this.translate.get('TOASTER_NOTIFICATIONS.USER_DELETED_SUCCESSFULLY').subscribe((translatedText: string) => {
        this._notificationService.showNotification(translatedText, 2)
      })
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  ngOnInit() {
    this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentUser)).subscribe((user) => {
      this.user = user
    })
  }

  removeSubuser(subuser) {
    const dialog = this._dialog.open(ConfirmationPopupComponent, {
      panelClass: 'medium-adaptive-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: 'MODAL_CONFIRM.REMOVE_CHILD',
    })

    dialog.afterClosed().subscribe((answer) => {
      if (!answer) return
      delete this.subUserStoreService[subuser.id.toString()]
      delete this.subUserStoreService.currentSubuser
      this._store.dispatch(new RemoveSubuser(subuser.id))
      // Select another subuser then
      const nextActiveUser = this.user.user.subusers[0]
      this.subUserStoreService.currentSubuser = JSON.stringify(nextActiveUser)
      this._store.dispatch(new SelectSubuser(nextActiveUser))
      this._store.dispatch(new GetSubuser(nextActiveUser.id))
    })
  }

  openAddSubuserDialog() {
    if (this.user.actualSubscriptions) {
      const dialogRef = this._dialog.open(AddSubuserDialogComponent, {
        disableClose: false,
        hasBackdrop: true,
        width: '28rem',
        data: this.user,
      })
      dialogRef.afterClosed().subscribe((result) => {
        this._store.dispatch(new GetAllSubuser())
      })
    } else {
      this.openSubscribeLesson()
    }
  }

  public openSubscribeLesson() {
    const dialog = this._dialog.open(LessonSubscribePopupComponent, {
      hasBackdrop: true,
      width: '24rem',
    })
  }

  public isLottie(url) {
    if (url.includes('.json')) {
      return true
    }
    return false
  }
}
