import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment'
import { Observable } from 'rxjs'
import { IThumbnail } from '../models/Avatar.model'

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private gatewayUrl: string

  constructor(private http: HttpClient) {
    this.gatewayUrl = `${environment.gatewayUrl}user/`
  }

  public getUser(): Observable<any> {
    return this.http.get(`${this.gatewayUrl}get`)
  }

  public updateUser(request: any) {
    return this.http.put(`${this.gatewayUrl}edit`, request)
  }

  public updateUserPassword(request: any) {
    return this.http.put(`${this.gatewayUrl}edit/password`, request)
  }

  public updateUserAvatar(avatar: IThumbnail) {
    return this.http.put(`${this.gatewayUrl}avatar`, { avatarId: avatar.id })
  }

  public manageBooksAuoplay(status) {
    return this.http.put(`${this.gatewayUrl}options/booksAutoplay`, {
      autoplayStatus: status,
    })
  }

  public deleteUser() {
    return this.http.delete(`${this.gatewayUrl}delete`)
  }
}
