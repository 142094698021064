import { Component, OnInit, OnDestroy, ElementRef, ViewChild, NgZone, AfterViewChecked, HostListener, ChangeDetectionStrategy } from '@angular/core'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { IAppState } from 'src/app/store/state/app.state'
import { Store, select } from '@ngrx/store'
import { selectCurrentSubuser } from 'src/app/store/selectors/subuser.selectors'
import { selectCurrentCourse } from 'src/app/store/selectors/courses.selectors'
import { selectCourseLessons } from 'src/app/store/selectors/lessons.selectors'
import { DisplayHeaderLesson, GetUnitWithLessons } from 'src/app/store/actions/lessons.actions'
import { combineLatest, Observable, Subject } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'
import { ISubuser } from 'src/app/models/Subuser.model'
import { ICourse } from 'src/app/models/Course.model'
import { SetHeaderValue } from 'src/app/store/actions/header.actions'
import { Router } from '@angular/router'
import { IUser } from 'src/app/models/User.model'
import { selectCurrentUser } from 'src/app/store/selectors/user.selectors'
import { selectNotification } from 'src/app/store/selectors/notification.selectors'
import { GetNotification } from 'src/app/store/actions/notification.actions'
import { TranslateService } from '@ngx-translate/core'
import { TimeLogService } from 'src/app/services/time-log.service'
import { RandomizeService } from 'src/app/services/randomize.service'
import { GetUser } from 'src/app/store/actions/user.actions'
import { GetStatistics } from 'src/app/store/actions/statistics.actions'
import { DeviceDetectorService } from 'ngx-device-detector'
import { AddSubuserDialogComponent } from 'src/app/shared/popups/Subuser-Dialogs/add-subuser/addSubuser.component'
import { AchievementsPopupComponent } from 'src/app/shared/popups/Achievement-Dialogs/achievements/achievements.component'
import { DinosaursPopupComponent } from 'src/app/shared/popups/Achievement-Dialogs/dinosaurs/dinosaurs.component'
import { StarsPopupComponent } from 'src/app/shared/popups/Achievement-Dialogs/stars/stars.component'
import { LessonSubscribePopupComponent } from 'src/app/shared/popups/Lesson-Dialogs/lesson-subscribe/lesson-subscribe.component'
import { LessonUnavailablePopupComponent } from 'src/app/shared/popups/Lesson-Dialogs/lesson-unavailable/lesson-unavailable.component'
import { ReportProblemPopupComponent } from 'src/app/shared/popups/Report-Dialogs/report-problem/report-problem.component'
import { AppMessageComponent } from 'src/app/shared/components/app-message/app-message.component'
import { Meta, Title } from '@angular/platform-browser'
import { mouseMoveScroll, mouseWheelScroll } from 'src/app/helpers/utils/LessonUtil/lessonMapScroll.util'
import { isAccessleCourses, isAccessLesson } from 'src/app/helpers/utils/LessonUtil/lesson.util'
import { rangomSpinner } from 'src/app/helpers/utils/spiner.util'
import { NotificationsComponent } from 'src/app/shared/components/notifications/notifications.component'
import { IAssignment } from '../../../models/Assignment.model'
import { selectStudentAssignments } from '../../../store/selectors/assignments.selectors'
import { AnimationItem } from 'lottie-web'
import { SubUserStoreService } from "src/app/store/services/subuser-store.service"

@Component({
  templateUrl: './lessons.component.html',
  styleUrls: ['./lessons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileLessonsComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('target') target: ElementRef

  @ViewChild('lessonsMap') lessonsMap: ElementRef

  private myDinoFound = false

  public ageRange = 0 // 0 - all lessons, 1 - video, 2 - all except video

  public subuser$: Observable<ISubuser> = this._store.pipe(select(selectCurrentSubuser))

  public course$: Observable<ICourse> = this._store.pipe(select(selectCurrentCourse))

  public unsubscribe$ = new Subject()

  public subuser: ISubuser

  public course: ICourse

  public currentUnitId: any

  public mapData: any

  public user: IUser

  public dateStart

  public images = false

  public accessibleCourses

  public userHasAccess: boolean

  public timerId
  public loaderClass: string = rangomSpinner()

  public deviceInfo = this.deviceService.getDeviceInfo()
  public isIOS = /iPad|iPhone|iPod/.test(navigator.platform)

  public title = 'Dinolingo - Language Starts Here - Language learning for kids'
  public description = 'Dinolingo - Language Starts Here - Language learning for kids'

  public currentLang = this.subUserStoreService.currentLanguage

  public noticPPdisplayed = 0

  public assignments: Array<IAssignment> = []

  constructor(
    private deviceService: DeviceDetectorService,
    private dialog: MatDialog,
    private _store: Store<IAppState>,
    public router: Router,
    public translate: TranslateService,
    public timeLogService: TimeLogService,
    public randomService: RandomizeService,
    private meta: Meta,
    private titleService: Title,
    private ngZone: NgZone,
    public subUserStoreService: SubUserStoreService
  ) {
    this.titleService.setTitle(this.title)
    this.meta.updateTag({ name: 'description', content: this.description })
  }

  public mapData$: Observable<any> = this._store.pipe(takeUntil(this.unsubscribe$), select(selectCourseLessons))

  public animationItem: AnimationItem[] = []

  ngOnInit() {
    if (this.deviceService.isMobile() && (window.outerHeight < 500 || window.outerWidth < 500)) {

      this.router.navigate(['/profile/dashboard'])
    }

    this.getCurrentUser()

    this.timeLogService.logNgOnInit()

    this._store.dispatch(new SetHeaderValue(this.router.url.split('/')[2]))

    this._store.dispatch(new DisplayHeaderLesson())

    this._store.pipe(takeUntil(this.unsubscribe$), select(selectStudentAssignments)).subscribe((res) => {
      this.assignments = res
    })
  }

  setAgeRange(subuser: ISubuser) {
    // SET AGE RANGE
    let age = parseInt(subuser.age)
    if (age) {
      if (age < 6) {
        this.ageRange = 1
      } else if (age > 10) {
        this.ageRange = 2
      } else {
        this.ageRange = 0
      }
    } else {
      this.ageRange = 0
    }
    // SET AGE RANGE END
  }

  getContentNotification(courseId) {
    this._store.pipe(takeUntil(this.unsubscribe$), select(selectNotification)).subscribe((notific) => {
      if (notific) {
        let isTargetCourse = false
        if (notific.notific_courses && notific.notific_courses.length > 0) {
          let courses = notific.notific_courses.map((a) => a.course_id)
          if (courses.includes(courseId)) {
            isTargetCourse = true
          }
        } else {
          isTargetCourse = true
        }

        let noticId = localStorage.getItem(`noticContent${courseId}`)
        if (isTargetCourse && parseInt(noticId) !== notific.id) {
          let content
          if (notific.notific_contents.length > 1) {
            let item = notific.notific_contents.findIndex((x) => x.lang === this.currentLang)
            if (item < 0) {
              item = notific.notific_contents.findIndex((x) => x.lang === 'en')
            }
            content = notific.notific_contents[item]
          } else {
            content = notific.notific_contents[0]
          }
          if (this.noticPPdisplayed != notific.id) {
            this.noticPPdisplayed = notific.id
            if (!this.deviceService.isMobile() && !this.deviceService.isTablet()) {
              this.openNotificationMessage(content, notific.id)
            }
          }
        }
      }
    })
  }

  /**
   * Function get current user
   */
  getCurrentUser() {
    this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentUser)).subscribe((user) => {
      if (!user) { return }

      if (user.accessibleCourses) {
        this.accessibleCourses = user.accessibleCourses
      }

      this.getCurrentSubuserAndCourse()

      this.user = user.user
      this.subUserStoreService.userId = this.user.id

      if (['teacherAdmin', 'teacher'].includes(user.user.role)) {
        return this.router.navigate(['/profile/teachers/students'])
      }

      if (user.userType === 'user') {
        this.isHasSubuser(this.user.subusers)
      }
    })
  }

  /**
   * GET CURRENT CHILD AND COURSE
   * GET CHILD STATISTIC FOR CURRENT COURSE
   */
  getCurrentSubuserAndCourse() {
    combineLatest([this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentSubuser)), this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentCourse))])
      .pipe(filter(([subuser, course]) => !!subuser && !!course))
      .subscribe(([subuser, course]) => {
        this.subuser = subuser

        this.setAgeRange(subuser)

        this.course = course
        this._store.dispatch(
          new GetStatistics({
            subuserId: subuser.id,
            courseId: course.id,
          })
        )

        this.userHasAccess = isAccessleCourses(this.accessibleCourses, this.course)

        this.getCurrentUnitId(subuser.options, course.id)

        this._store.dispatch(
          new GetUnitWithLessons({
            subuserId: subuser.id.toString(),
            courseId: course.id.toString(),
            unitId: this.currentUnitId ? this.currentUnitId.toString() : null,
            ageRange: this.ageRange,
          })
        )

        this.getCourseWithUnits()

        this._store.dispatch(new GetNotification({ lang: this.currentLang, course: this.course.id }))

        this.getContentNotification(course.id)
      })
  }

  getCourseWithUnits() {
    this._store.pipe(takeUntil(this.unsubscribe$), select(selectCourseLessons)).subscribe((courseWithUnits) => {
      if (courseWithUnits) {
        this.mapData = courseWithUnits
      }
    })
  }

  /**
   * Function Check does it have user subuser
   * @param subuser
   */
  isHasSubuser(subuser) {
    if (subuser.length == 0) {
      const dialog = this.dialog.open(AddSubuserDialogComponent, {
        width: '28rem',
        height: '28rem',
        disableClose: true,
      })
      dialog.afterClosed().subscribe(() => {
        this._store.dispatch(new GetUser())
      })
    }
  }

  ngAfterViewChecked() {
    const myDino = this.target

    if (!this.myDinoFound && myDino) {
      this.myDinoFound = true
      this.timerId = setTimeout(() => {
        myDino.nativeElement.scrollIntoView({
          block: 'nearest',
          inline: 'center',
          behavior: 'smooth',
        })
      }, 100)
    }
  }

  ngOnDestroy() {
    clearTimeout(this.timerId)
    this.timeLogService.logNgOnDestroy(this.subuser?.id)
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  /**
   * REQUEST TO GET NEXT UNIT FOR CURRENT COURSE
   * @param unitId
   */
  public nextUnit(unitId) {
    this._store.dispatch(
      new GetUnitWithLessons({
        subuserId: this.subuser.id.toString(),
        courseId: this.course.id.toString(),
        unitId,
        ageRange: this.ageRange,
      })
    )

    this.lessonsMap.nativeElement.scrollLeft = 0
  }

  /**
   * Function get lessons from prev unit
   * @param unitId
   */
  public prevUnit(unitId) {
    this._store.dispatch(
      new GetUnitWithLessons({
        subuserId: this.subuser.id.toString(),
        courseId: this.course.id.toString(),
        unitId,
        ageRange: this.ageRange,
      })
    )
  }

  public openAchievementsPopup($event) {
    this.dialog.open(AchievementsPopupComponent, {
      panelClass: 'big-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      disableClose: true,
    })
  }

  public openDinosaursPopup($event) {
    this.dialog.open(DinosaursPopupComponent, {
      panelClass: 'big-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      disableClose: true,
    })
  }

  public openStarsPopup($event) {
    this.dialog.open(StarsPopupComponent, {
      panelClass: 'big-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      disableClose: true,
    })
  }

  public openSubscribeLesson() {
    this.dialog.open(LessonSubscribePopupComponent, {
      hasBackdrop: true,
    })
  }

  public openUnavailableLesson() {
    this.dialog.open(LessonUnavailablePopupComponent, {
      hasBackdrop: true,
    })
  }

  public openNotificationMessage(data, id) {
    this.dialog
      .open(NotificationsComponent, {
        hasBackdrop: true,
        width: '32rem',
        data: data,
      })
      .afterClosed()
      .subscribe(() => {
        localStorage.setItem(`noticContent${this.course.id}`, id) // dinolingo.com localhost stage-learn.dinolearning.com
      })
  }

  public openMobileAppMessage() {
    this.dialog.open(AppMessageComponent, {
      hasBackdrop: true,
      width: '32rem',
      height: '33rem',
    })
  }

  /**
   * Function checed have user access for go to lesson content
   * @param lesson
   */
  public goToLesson(lesson) {
    const dataForCheck = {
      lesson,
      accessibleCourses: this.accessibleCourses,
      subuser: this.subuser,
      id: this.course.id,
    }

    const isAccess = isAccessLesson(dataForCheck)

    if (isAccess == null) return this.openSubscribeLesson()

    const isAssignment = this.subuser.studentId && this.assignments.some((a: IAssignment) => a.lessonId === lesson.id)

    if (isAccess === true || isAssignment === true) {
      this.router.navigate([`/profile/lesson/${lesson.id}/${lesson.type.slug}`])
    } else {
      this.openUnavailableLesson()
    }
  }

  /**
   * Function event lisner horizontal Scroll
   * @param
   */
  horizontalScroll() {
    mouseMoveScroll(this.lessonsMap)
  }
  /**
   * Function event lisner horizontal Scroll mouse wheel
   * @param
   */
  horizontalScrollWheel() {
    mouseWheelScroll(this.lessonsMap)
  }

  public loadImage() {
    this.images = !this.images
  }

  public openReportPopup() {
    this.dialog.open(ReportProblemPopupComponent, {
      closeOnNavigation: true,
      hasBackdrop: true,
      panelClass: 'medium-adaptive-popup',
    })
  }
  /**
   * Function for get currentUnitId from array of subuser course options
   * @param options
   * @param courseId
   */
  private getCurrentUnitId(options, courseId) {
    for (let i = 0; i < options.length; i++) {
      if (options[i].courseId == courseId) {
        this.currentUnitId = options[i].currentUnitId
        return
      }
    }

    this.currentUnitId = null
  }

  public getEggPath(type) {
    if (type == 'Video') {
      return '/assets/images/lessons/items/EggGreen.json'
    }
    if (type == 'Video Song') {
      return '/assets/images/lessons/items/EggPurple.json'
    }
    if (type == 'Game') {
      return '/assets/images/lessons/items/EggRed.json'
    }
    if (type == 'Mini Test') {
      return '/assets/images/lessons/items/EggYellow.json'
    }
    if (type == 'Book') {
      return '/assets/images/lessons/items/EggBlue.json'
    }
    return '/assets/images/lessons/items/EggDarkBlue.json'
  }

  public playAnim(index) {
    this.ngZone.runOutsideAngular(() => {
      this.animationItem[index].play()
    })
  }

  public stopAnim(index) {
    this.ngZone.runOutsideAngular(() => {
      this.animationItem[index].pause()
    })
  }

  public animationCreated(animationItem: AnimationItem, index): void {
    this.animationItem[index] = animationItem
  }

  public isLessonLocked(lesson) {
    if ((!lesson.isFree && !this.userHasAccess) || (!lesson.completed && !lesson.current && !this.subuser.lessonMode)) {
      if (this.subuser.studentId) {
        if (this.assignments.some((a: IAssignment) => a.lessonId === lesson.id)) {
          return false
        }
      }

      return true
    }

    return false
  }
}
