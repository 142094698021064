import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError, withLatestFrom } from 'rxjs/operators'
import { Store, select } from '@ngrx/store'
import { IAppState } from '../../store/state/app.state'
import { RefreshToken, EAuthActions, RefreshTokenSuccess } from '../../store/actions/auth.actions'
import { selectLatestRequest } from '../../store/selectors/auth.selectors'
import { Actions, ofType } from '@ngrx/effects'
import { NotificationService } from '../../services/notification.service'
import { SpinnerOverlayService } from '../../services/spinner.service'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { ServerErrorPopupComponent } from '../../shared/popups/Report-Dialogs/server-error/server-error.component'
import { AuthService } from 'src/app/services/auth.service'
import { environment } from 'src/environments/environment'

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  constructor(
    private _store: Store<IAppState>,
    private _notifications: NotificationService,
    private updates$: Actions,
    private store$: Store<IAppState>,
    private httpHandler: HttpHandler,
    private _spinnerService: SpinnerOverlayService,
    private dialog: MatDialog,
    public _authService: AuthService
  ) {
    this.updates$.pipe(ofType<RefreshTokenSuccess>(EAuthActions.RefreshTokenSuccess), withLatestFrom(this.store$.pipe(select(selectLatestRequest)))).subscribe(([action, request]) => {
      this.httpHandler.handle(request)
    })
  }

  private handleAuthError(err: HttpErrorResponse, request: HttpRequest<any>) {
    //TODO RELOAD TEST
    if (err.status == 401) {
      this._store.dispatch(new RefreshToken(request))
    } else if (err.status == 500) {
      this.dialog.open(ServerErrorPopupComponent, {
        panelClass: 'medium-adaptive-popup',
      })
    } else if (err.status == 403) {
      this._authService.logout()

      window.location.href = environment.logoutRedirectUrl
    } else if (err.status >= 400) {
      this._notifications.showNotification(err.error.error, 2)
    }

    this._spinnerService.hide()

    return throwError(err)
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const jwtToken = localStorage.getItem('accessToken') || null
    request = request.clone({
      setHeaders: { Authorization: `Bearer ${jwtToken}` },
    })
    return next.handle(request).pipe(catchError((err, caught) => this.handleAuthError(err, request)))
  }
}
