import { Component, OnDestroy, OnInit } from '@angular/core'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { Store, select } from '@ngrx/store'
import { IAppState } from 'src/app/store/state/app.state'
import { ISubuser, SubuserRemoveCourseRequest } from 'src/app/models/Subuser.model'
import { selectSubuser } from 'src/app/store/selectors/subuser.selectors'
import { GetSubuser, DeleteCourseFromSubuserSuccess, ESubuserActions, DeleteCourseFromSubuser, UpdateSubuserAvatar, SelectSubuser, UpdateSubuserAvatarSuccess, SetSubuserUnlockLessons } from 'src/app/store/actions/subuser.actions'
import { Observable, Subject, Subscription } from 'rxjs'
import { ActivatedRoute, Router } from '@angular/router'
import { Actions, ofType } from '@ngrx/effects'
import { filter, takeUntil } from 'rxjs/operators'
import { ICourse } from 'src/app/models/Course.model'
import { selectAllCourses, selectCurrentCourse } from 'src/app/store/selectors/courses.selectors'
import { GetAllCourses } from 'src/app/store/actions/courses.actions'
import { GetStatistics } from 'src/app/store/actions/statistics.actions'
import { TranslateService } from '@ngx-translate/core'
import { SubuserService } from 'src/app/services/subuser.service'
import { NotificationService } from 'src/app/services/notification.service'
import { AvatarSelectPopupComponent } from 'src/app/shared/popups/Subuser-Dialogs/avatar-select/avatar-select.component'
import { EditSubuserDialogComponent } from 'src/app/shared/popups/Subuser-Dialogs/edit-subuser/editSubuser.component'
import { ConfirmationPopupComponent } from 'src/app/shared/popups/Control-Dialogs/confirmation/confirmation.component'
import { FormatTextService } from '../../../../services/formatText.service'
import { UpdateSubuserSuccess } from 'src/app/store/actions/subuser.actions'
import { selectCurrentUser } from 'src/app/store/selectors/user.selectors'
import { SubUserStoreService } from "src/app/store/services/subuser-store.service"

@Component({
  templateUrl: './subuser.component.html',
  styleUrls: ['./subuser.component.scss'],
})
export class SubuserComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<boolean>()

  public user: any

  constructor(
    private updates$: Actions,
    private _dialog: MatDialog,
    private _store: Store<IAppState>,
    private _route: ActivatedRoute,
    public translate: TranslateService,
    public _subuserService: SubuserService,
    public _notificationService: NotificationService,
    private textService: FormatTextService,
    public router: Router,
    public subUserStoreService: SubUserStoreService
  ) {
    this.updates$.pipe(ofType<UpdateSubuserSuccess>(ESubuserActions.UpdateSubuserSuccess), takeUntil(this.destroyed$)).subscribe((res: UpdateSubuserSuccess) => {
      this.translate.get('TOASTER_NOTIFICATIONS.USER_UPDATED_SUCCESSFULLY').subscribe((translatedText: string) => {
        this._notificationService.showNotification(translatedText, 2)
      })
    })
  }

  private unsubscribe$ = new Subject()

  public ageRange = ''

  public getAgeRange(age: string) {
    let ageNumber = parseInt(age)
    if (ageNumber) {
      if (ageNumber < 6) {
        return '2 - 5'
      } else if (ageNumber > 10) {
        return '11+'
      } else {
        return '6 - 10'
      }
    } else {
      return '6 - 10'
    }
  }

  // coursesArray = [];

  // getCoursesArray(user){
  //   this.coursesArray = [];
  //   const data = user.actualSubscriptions;
  //   data.forEach(element => {
  //     const obj = {
  //       id: element.plan.courseId,
  //       name: element.plan.name
  //     }
  //     this.coursesArray.push(obj);
  //   });
  // }

  ngOnInit() {
    this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentUser)).subscribe((user) => {
      this.user = user
    })

    this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentCourse)).subscribe((res) => {
      this.currentCourse = res
    })
    this._store.dispatch(new GetSubuser(this.subuserId))
    this._store.dispatch(new GetAllCourses())
    this._store.dispatch(
      new GetStatistics({
        subuserId: this.subuserId,
        courseId: this.currentCourse.id,
      })
    )
  }

  public currentCourse

  public subuserId: number = parseInt(this._route.snapshot.params.id)

  public subuser: ISubuser

  public isChecked: boolean

  public selectedCourse: number

  public displayTooltip = false

  public avatars = []

  public formatLangName(course) {
    return this.textService.formatLangName(course)
  }

  public subuser$: Observable<ISubuser> = this._store.pipe(takeUntil(this.unsubscribe$), select(selectSubuser))

  public courses$: Observable<Array<ICourse>> = this._store.pipe(select(selectAllCourses))

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  public subuserSubscription: Subscription = this._store
    .pipe(
      takeUntil(this.unsubscribe$),
      select(selectSubuser),
      filter((subuser) => !!subuser)
    )
    .subscribe((subuser) => {
      this.subuser = subuser
      this.ageRange = this.getAgeRange(this.subuser.age)
      this.isChecked = this.subuser.lessonMode
      // Changed Code
      this.avatars = [subuser.avatar.src]
    })

  public deleteCourseSubscription: Subscription = this.updates$.pipe(takeUntil(this.unsubscribe$), ofType<DeleteCourseFromSubuserSuccess>(ESubuserActions.DeleteCourseFromSubuserSuccess)).subscribe((res: DeleteCourseFromSubuserSuccess) => {
    this._store.dispatch(new SelectSubuser(this.subuser))
    this._store.dispatch(new GetSubuser(this.subuser.id))
  })

  public updateSubuserAvatar: Subscription = this.updates$.pipe(takeUntil(this.unsubscribe$), ofType<UpdateSubuserAvatarSuccess>(ESubuserActions.UpdateSubuserAvatarSuccess)).subscribe((res: UpdateSubuserAvatarSuccess) => {
    this._store.pipe(takeUntil(this.unsubscribe$), select(selectSubuser)).subscribe((subuser) => {
      this._store.dispatch(new SelectSubuser(subuser))
    })
  })

  removeCourse(courseId) {
    const dialog = this._dialog.open(ConfirmationPopupComponent, {
      panelClass: 'medium-adaptive-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: 'MODAL_CONFIRM.REMOVE_COURSE',
    })

    dialog.afterClosed().subscribe((answer) => {
      if (!answer) return

      const request: SubuserRemoveCourseRequest = new SubuserRemoveCourseRequest()
      request.subuserId = this.subuserId
      request.courseId = courseId
      this._store.dispatch(new DeleteCourseFromSubuser(request))
      this._store.pipe(takeUntil(this.unsubscribe$), select(selectSubuser)).subscribe((subuser) => {
        this._store.dispatch(new SelectSubuser(subuser))

        this.subUserStoreService.subuserCurrentCourse[this.subuser.id.toString()] = JSON.stringify(this.subuser.courses[0])
      })
      this.translate.get('TOASTER_NOTIFICATIONS.COURSE_DELETED_SUCCESSFULLY').subscribe((translatedText: string) => {
        this._notificationService.showNotification(translatedText, 2)
      })
    })
  }

  addCourse(courseId) {
    const request = {
      subuserId: this.subuserId,
      courseId,
    }

    this._subuserService.addCourseToSubuser(request).subscribe(() => {
      this._store.dispatch(new GetSubuser(this.subuserId))
      this.translate.get('TOASTER_NOTIFICATIONS.COURSE_ADDED_SUCCESSFULLY').subscribe((translatedText: string) => {
        this._notificationService.showNotification(translatedText, 2)
      })
    })

    this._store.pipe(takeUntil(this.unsubscribe$), select(selectSubuser)).subscribe((subuser) => {
      this.subUserStoreService.currentSubuser = JSON.stringify(subuser)
    })

    this.selectedCourse = undefined
  }

  openEditSubuserDialog(subuser: ISubuser): void {
    this._dialog
      .open(EditSubuserDialogComponent, {
        panelClass: 'medium-adaptive-popup',
        disableClose: false,
        hasBackdrop: true,
        data: subuser,
      })
      .afterClosed()
      .subscribe((proceed) => {
        if (!proceed) return
        this._store.dispatch(new GetSubuser(subuser.id))
        this._store.pipe(takeUntil(this.unsubscribe$), select(selectSubuser)).subscribe((subuser) => {
          this.subUserStoreService.currentSubuser = JSON.stringify(subuser)
          this._store.dispatch(new SelectSubuser(subuser))
        })
      })
  }

  /**
   * Open update avatar dialog for subuser
   * @sankyago
   * And then, get user object
   * @param {IThumbnail} thumbnail
   */
  openUpdateAvatarPopup() {
    const dialog = this._dialog.open(AvatarSelectPopupComponent, {
      hasBackdrop: true,
      width: '32rem',
      height: '32rem',
    })
    dialog
      .afterClosed()
      .pipe(filter((avatar) => !!avatar))
      .subscribe((avatar) => {
        this._store.dispatch(new UpdateSubuserAvatar({ ...avatar, subuserId: this.subuserId }))
      })
  }

  public unlock(isChecked) {
    this.isChecked = isChecked
    const request = {
      subuserId: this.subuserId,
      lessonMode: isChecked,
    }
    this._store.dispatch(new SetSubuserUnlockLessons(request))
  }

  public changeToolTip(event: Event) {
    this.displayTooltip = !this.displayTooltip
    event.stopPropagation()
  }

  public closeToolTip() {
    if (this.displayTooltip) {
      this.displayTooltip = false
    }
  }

  public isLottie(url) {
    if (url.includes('.json')) {
      return true
    }
    return false
  }
}
