import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { CoursesService } from '../../../../services/courses.service'
import { Store } from '@ngrx/store'
import { IAppState } from '../../../../store/state/app.state'
import { TranslateService } from '@ngx-translate/core'
import { ISubuser } from '../../../../models/Subuser.model'
import { rangomSpinner } from '../../../../helpers/utils/spiner.util'
import { Subject } from 'rxjs'
import { isEmptyObj } from '../../../../helpers/utils/checkObject'
import { generateDate } from '../../../../helpers/utils/generateDate'
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'

@Component({
  templateUrl: './all-detailed-reports.component.html',
  styleUrls: ['./all-detailed-reports.component.scss'],
})
export class AllDetailedReportsComponent implements OnInit, OnDestroy {
  constructor(private courseService: CoursesService, private _store: Store<IAppState>, public dialogRef: MatDialogRef<AllDetailedReportsComponent>, public translate: TranslateService, @Inject(MAT_DIALOG_DATA) public dialogData) { }
  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }
  public data = false

  public detailedSubuserReport
  public firstSubuserDate: ISubuser
  public datesArray = []
  public currentSubuser
  public currentCourse
  public selected
  public dateArray
  public emptyObj = true
  public loaderClass: string = rangomSpinner()

  private unsubscribe$ = new Subject()

  ngOnInit() {
    let selectedKey = this.generateDateReports(this.dialogData.report.subuser.createdAt)

    this.getSubuserDetailedReport(selectedKey)
  }

  getSubuserDetailedReport(selectedKey) {
    this.courseService
      .getDetailedReportByDate({
        subuserId: this.dialogData.report.subuser.id,
        courseId: this.dialogData.course.id,
        firstDay: this.dateArray[selectedKey].firstDay,
        lastDay: this.dateArray[selectedKey].lastDay,
      })
      .subscribe((detailedReport) => {
        if (detailedReport) {
          this.data = true

          this.detailedSubuserReport = detailedReport

          this.emptyObj = isEmptyObj(this.detailedSubuserReport.tableData.response)
        }
      })
  }

  getSubuserMonthReport(month) {
    const request = {
      subuserId: this.dialogData.report.subuser.id,
      courseId: this.dialogData.course.id,
      firstDay: this.dateArray[month].firstDay,
      lastDay: this.dateArray[month].lastDay,
    }

    this.courseService.getDetailedReportByDate(request).subscribe((detailedReport) => {
      if (detailedReport) {
        this.detailedSubuserReport = detailedReport

        this.emptyObj = isEmptyObj(this.detailedSubuserReport.tableData.response)
      }
    })
  }

  /**
   *  Generate date to select
   *  date registration and now date
   */
  public generateDateReports(subuserCreate: string | number | Date) {
    const { selected, resultArray, selectedKey } = generateDate(subuserCreate, this.selected, this.dateArray)

    this.selected = selected
    this.dateArray = resultArray

    return selectedKey
  }
}
