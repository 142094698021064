import { OnInit, Component } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { Router, ActivatedRoute } from '@angular/router'
import { SEOService } from 'src/app/services/seo.service'
import { LocalizeHelperService } from 'src/app/services/localizHelper.service'

@Component({
  templateUrl: './privacy2.component.html',
  styleUrls: ['./privacy2.component.scss'],
})
export class Privacy2Component implements OnInit {


  constructor(public translate: TranslateService, private router: Router, private _seoService: SEOService, private route: ActivatedRoute, private localizeHelperService: LocalizeHelperService) {}
  public currentLang: string
  public name: string
  public title: string
  public description: string
  public metaTitle = ''
  public url: string = this.router.url

  public langParam = this.route.snapshot.params && this.route.snapshot.params.lang ? this.route.snapshot.params.lang : ''

  public lURL

  ngOnInit() {
    window.location.href = 'https://help.dinolingo.com/article/493-privacy'
  }
}
