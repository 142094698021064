import { Component, OnInit } from '@angular/core'

@Component({
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss'],
})
export class SubscriptionsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
