import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { map, take, filter, switchMap } from 'rxjs/operators'
import { selectCurrentUser } from '../../store/selectors/user.selectors'
import { Observable, of } from 'rxjs'
import { GetUser } from '../../store/actions/user.actions'

@Injectable()
export class CheckRoleGuard implements CanActivate {
  constructor(private _router: Router, private _store: Store<any>) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | import('@angular/router').UrlTree> {
    return this._store.select(selectCurrentUser).pipe(
      take(1),
      switchMap(user => {
        if (user === null) {
          this._store.dispatch(new GetUser());
          return this._store.select(selectCurrentUser).pipe(
            filter(user => user !== null),
            take(1)
          );
        }

        return of(user);
      }),
      map(user => {
        const isAuth = !!user;
        if (isAuth) {
          const allowedRoles = route.data.allowedRoles;
          if (allowedRoles.indexOf(user.userType) !== -1) {
            return true;
          }
        }

        return this._router.createUrlTree(['/auth']);
      })
    );
  }
}
