import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AboutUSComponent } from './about-us/about-us.component'
import { CurriculumComponent } from './curriculum/curriculum.component'
import { HomeComponent } from './home/home.component'
import { HomeschoolersComponent } from './homeschoolers/homeschoolers.component'
import { HowItWorksComponent } from './howItWorks/howItWorks.component'
import { LandingComponent } from './landing.component'
import { LanguageCoursesComponent } from './languageCourses/langcourses.component'
import { LearnForKidsComponent } from './learnForKids/learnForKids.component'
import { ParentsGuideComponent } from './parentsGuide/parentsGuide.component'
import { Privacy2Component } from './privacy2/privacy2.component'
import { ReviewsComponent } from './reviews/reviews.component'
import { SchoolsComponent } from './schools/schools.component'
import { Terms2Component } from './terms2/terms2.component'
import { seometadata } from '../../../assets/i18n/seometadata'
import { langURLLocalizationsResolver } from '../../helpers/resolvers/langURLLocalizations.resolver'
import { LoginAdminComponent } from '../auth/login-admin/login-admin.component'
import { NotFoundComponent } from './not-found/not-found.component'
import { BlogComponent } from './blog/blog.component'
import { BlogListComponent } from './blog/list/blogList.component'
import { LandingRedirectGuard } from 'src/app/helpers/guards/landingRedirect.guard'

const landingRoutes: Routes = [
  {
    path: '',
    component: LandingComponent,
    canActivateChild: [LandingRedirectGuard],
    children: [
      {
        path: '',
        data: {
          meta: seometadata.homepage,
          lang: '',
        },
        component: HomeComponent,
      },
      {
        path: 'en',
        data: {
          meta: seometadata.homepage,
          lang: 'en',
        },
        component: HomeComponent,
      },
      {
        path: 'fr',
        data: {
          meta: seometadata.homepage,
          lang: 'fr',
        },
        component: HomeComponent,
      },
      {
        path: 'ru',
        data: {
          meta: seometadata.homepage,
          lang: 'ru',
        },
        component: HomeComponent,
      },
      {
        path: 'ua',
        data: {
          meta: seometadata.homepage,
          lang: 'ua',
        },
        component: HomeComponent,
      },
      {
        path: 'es',
        data: {
          meta: seometadata.homepage,
          lang: 'es',
        },
        component: HomeComponent,
      },
      {
        path: 'it',
        data: {
          meta: seometadata.homepage,
          lang: 'it',
        },
        component: HomeComponent,
      },
      {
        path: 'de',
        data: {
          meta: seometadata.homepage,
          lang: 'de',
        },
        component: HomeComponent,
      },
      {
        path: 'pt',
        data: {
          meta: seometadata.homepage,
          lang: 'pt',
        },
        component: HomeComponent,
      },
      {
        path: 'pl',
        data: {
          meta: seometadata.homepage,
          lang: 'pl',
        },
        component: HomeComponent,
      },
      {
        path: 'tr',
        data: {
          meta: seometadata.homepage,
          lang: 'tr',
        },
        component: HomeComponent,
      },
      {
        path: 'hi',
        data: {
          meta: seometadata.homepage,
          lang: 'hi',
        },
        component: HomeComponent,
      },
      {
        path: 'jp',
        data: {
          meta: seometadata.homepage,
          lang: 'jp',
        },
        component: HomeComponent,
      },
      {
        path: 'ar',
        data: {
          meta: seometadata.homepage,
          lang: 'ar',
        },
        component: HomeComponent,
      },
      {
        path: 'zh',
        data: {
          meta: seometadata.homepage,
          lang: 'zh',
        },
        component: HomeComponent,
      },
      {
        path: 'ko',
        data: {
          meta: seometadata.homepage,
          lang: 'ko',
        },
        component: HomeComponent,
      },
      {
        path: `about-us`,
        data: seometadata.about_us,
        component: AboutUSComponent,
      },
      {
        path: `:lang/about-us`,
        data: seometadata.about_us,
        component: AboutUSComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: `curriculum`,
        data: seometadata.curriculum,
        component: CurriculumComponent,
      },
      {
        path: `:lang/curriculum`,
        data: seometadata.curriculum,
        component: CurriculumComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: `homeschool`,
        data: seometadata.homeschool,
        component: HomeschoolersComponent,
      },
      {
        path: `:lang/homeschool`,
        data: seometadata.homeschool,
        component: HomeschoolersComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: `how-it-works`,
        data: seometadata.how_it_works,
        component: HowItWorksComponent,
      },
      {
        path: `:lang/how-it-works`,
        data: seometadata.how_it_works,
        component: HowItWorksComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: `language-courses`,
        data: seometadata.courses,
        component: LanguageCoursesComponent,
      },
      {
        path: `:lang/language-courses`,
        data: seometadata.courses,
        component: LanguageCoursesComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: `parents`,
        data: seometadata.parents,
        component: ParentsGuideComponent,
      },
      {
        path: `:lang/parents`,
        data: seometadata.parents,
        component: ParentsGuideComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: `reviews`,
        data: seometadata.reviews,
        component: ReviewsComponent,
      },
      {
        path: `:lang/reviews`,
        data: seometadata.reviews,
        component: ReviewsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: `schools-teachers`,
        data: seometadata.schools,
        component: SchoolsComponent,
      },
      {
        path: `:lang/schools-teachers`,
        data: seometadata.schools,
        component: SchoolsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: `terms-of-use`,
        data: seometadata.terms,
        component: Terms2Component,
      },
      {
        path: `privacy-policy`,
        data: seometadata.privacy,
        component: Privacy2Component,
      },
      {
        path: 'learn-albanian-for-kids',
        data: seometadata.learn.albanian,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-albanian-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-arabic-for-kids',
        data: seometadata.learn.arabic,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-arabic-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-armenian-for-kids',
        data: seometadata.learn.armenian,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-armenian-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-bulgarian-for-kids',
        data: seometadata.learn.bulgarian,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-bulgarian-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-cantonese-for-kids',
        data: seometadata.learn.cantonese,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-cantonese-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-chinese-for-kids',
        data: seometadata.learn.chinese,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-chinese-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-creole-for-kids',
        data: seometadata.learn.creole,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-creole-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-croatian-for-kids',
        data: seometadata.learn.croatian,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-croatian-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-czech-for-kids',
        data: seometadata.learn.czech,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-czech-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-danish-for-kids',
        data: seometadata.learn.danish,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-danish-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-dutch-for-kids',
        data: seometadata.learn.dutch,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-dutch-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-english-for-kids',
        data: seometadata.learn.english,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-english-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-euportuguese-for-kids',
        data: seometadata.learn.euportuguese,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-euportuguese-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-finnish-for-kids',
        data: seometadata.learn.finnish,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-finnish-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-french-for-kids',
        data: seometadata.learn.french,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-french-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-german-for-kids',
        data: seometadata.learn.german,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-german-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-greek-for-kids',
        data: seometadata.learn.greek,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-greek-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-gujarati-for-kids',
        data: seometadata.learn.gujarati,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-gujarati-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-hawaiian-for-kids',
        data: seometadata.learn.hawaiian,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-hawaiian-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-hebrew-for-kids',
        data: seometadata.learn.hebrew,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-hebrew-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-hindi-for-kids',
        data: seometadata.learn.hindi,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-hindi-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-hungarian-for-kids',
        data: seometadata.learn.hungarian,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-hungarian-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-indonesian-for-kids',
        data: seometadata.learn.indonesian,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-indonesian-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-irish-for-kids',
        data: seometadata.learn.irish,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-irish-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-italian-for-kids',
        data: seometadata.learn.italian,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-italian-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-japanese-for-kids',
        data: seometadata.learn.japanese,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-japanese-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
      },
      {
        path: 'learn-korean-for-kids',
        data: seometadata.learn.korean,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-korean-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-latin-for-kids',
        data: seometadata.learn.latin,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-latin-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-malay-for-kids',
        data: seometadata.learn.malay,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-malay-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-norwegian-for-kids',
        data: seometadata.learn.norwegian,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-norwegian-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-persian-for-kids',
        data: seometadata.learn.persian,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-persian-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-polish-for-kids',
        data: seometadata.learn.polish,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-polish-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-portuguese-for-kids',
        data: seometadata.learn.portuguese,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-portuguese-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-punjabi-for-kids',
        data: seometadata.learn.punjabi,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-punjabi-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-romanian-for-kids',
        data: seometadata.learn.romanian,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-romanian-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-russian-for-kids',
        data: seometadata.learn.russian,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-russian-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-serbian-for-kids',
        data: seometadata.learn.serbian,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-serbian-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-slovak-for-kids',
        data: seometadata.learn.slovak,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-slovak-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-slovenian-for-kids',
        data: seometadata.learn.slovenian,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-slovenian-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-spanish-for-kids',
        data: seometadata.learn.spanish,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-spanish-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-swahili-for-kids',
        data: seometadata.learn.swahili,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-swahili-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-swedish-for-kids',
        data: seometadata.learn.swedish,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-swedish-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-tagalog-for-kids',
        data: seometadata.learn.tagalog,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-tagalog-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-thai-for-kids',
        data: seometadata.learn.thai,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-thai-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-turkish-for-kids',
        data: seometadata.learn.turkish,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-turkish-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-ukrainian-for-kids',
        data: seometadata.learn.ukrainian,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-ukrainian-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-urdu-for-kids',
        data: seometadata.learn.urdu,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-urdu-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-vietnamese-for-kids',
        data: seometadata.learn.vietnamese,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-vietnamese-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: 'learn-welsh-for-kids',
        data: seometadata.learn.welsh,
        component: LearnForKidsComponent,
      },
      {
        path: ':lang/learn-welsh-for-kids',
        data: seometadata.learn,
        component: LearnForKidsComponent,
        resolve: { foo: langURLLocalizationsResolver },
      },
      {
        path: `blog`,
        data: seometadata.blog,
        component: BlogListComponent,
      },
      {
        path: `blog/:slug`,
        component: BlogComponent,
      },
      {
        path: 'login-as-admin/:accessToken/:refreshToken',
        component: LoginAdminComponent,
      },
      {
        path: '**',
        component: NotFoundComponent,
        pathMatch: 'full',
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(landingRoutes)],
  exports: [RouterModule],
})
export class LandingRoutingModule { }
