import { Component, Inject, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { Subject } from 'rxjs'
import { Actions, ofType } from '@ngrx/effects'
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { Store } from '@ngrx/store'
import { IAppState } from '../../../../store/state/app.state'
import { TranslateService } from '@ngx-translate/core'
import { takeUntil } from 'rxjs/operators'
import { AddTeacher, AddTeacherFailure, AddTeacherSuccess, ETeachersActions } from '../../../../store/actions/teachers.actions'
import { ITeacherRequest } from '../../../../models/Teacher.model'
import { NotificationService } from 'src/app/services/notification.service'

@Component({
  templateUrl: './add-teacher.component.html',
  styleUrls: ['./add-teacher.component.scss'],
})
export class AddTeacherDialogComponent implements OnInit {
  public form: UntypedFormGroup

  private destroyed$ = new Subject<boolean>()

  public unsubscribe$ = new Subject()

  constructor(
    private updates$: Actions,
    public dialogRef: MatDialogRef<AddTeacherDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _store: Store<IAppState>,
    public translate: TranslateService,
    private _notificationService: NotificationService,
  ) {
    this.updates$.pipe(ofType<AddTeacherSuccess>(ETeachersActions.AddTeacherSuccess), takeUntil(this.destroyed$)).subscribe((res: AddTeacherSuccess) => {
      this.dialogRef.close(res)
      this._notificationService.showNotification(this.translate.instant('TEACHER.CREATED'), 2)
    })

    this.updates$.pipe(ofType<AddTeacherFailure>(ETeachersActions.AddTeacherFailure), takeUntil(this.destroyed$)).subscribe((res: any) => {
      if (res.payload?.error?.clientMessage.includes('This account already exists')) {
        this.form.controls['email'].setErrors({ duplicate: res.payload.error.clientMessage })
      } else {
        this.dialogRef.close(res)
      }
    })
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      firstName: new UntypedFormControl('', [Validators.required]),
      lastName: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.required]),
      password: new UntypedFormControl('', [Validators.required]),
      schoolId: new UntypedFormControl('', [Validators.required]),
    })

    this.form.patchValue({
      schoolId: this.data.schoolId,
    })
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

  createTeacher(form: UntypedFormGroup) {
    form.controls.email.setValue(form.value.email.trim())
    const request: ITeacherRequest = form.value
    this._store.dispatch(new AddTeacher(request))
  }
}
