import { Component, OnDestroy, OnInit } from '@angular/core'
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { Router } from '@angular/router'
import { Store, select } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { GetDinosaurs } from 'src/app/store/actions/statistics.actions'
import { selectDinosaurs } from 'src/app/store/selectors/statistics.selectors'
import { selectCurrentSubuser } from 'src/app/store/selectors/subuser.selectors'
import { selectCurrentUser } from 'src/app/store/selectors/user.selectors'
import { IAppState } from 'src/app/store/state/app.state'
import { DinoViewPopupComponent } from '../dino-view/dino-view.component'
import { AnimationOptions } from 'ngx-lottie'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
  templateUrl: 'dinosaurs.component.html',
  styleUrls: ['dinosaurs.component.scss'],
})
export class DinosaursPopupComponent implements OnDestroy, OnInit {
  public unsubscribe$ = new Subject()

  // TODO: What is this?
  public subuser$ = this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentSubuser)).subscribe((subuser) => {
    if (subuser) this._store.dispatch(new GetDinosaurs(subuser.id))
  })

  public user$ = this._store.pipe(select(selectCurrentUser))

  public dinosaurs

  public options: AnimationOptions[] = []

  public openedDinos = 0;

  constructor(public dialogRef: MatDialogRef<DinosaursPopupComponent>, private _store: Store<IAppState>, private router: Router, private _dialog: MatDialog, public translate: TranslateService) { }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  ngOnInit() {
    this._store.pipe(takeUntil(this.unsubscribe$), select(selectDinosaurs)).subscribe((dino) => {
      if (!dino) { return }

      this.dinosaurs = dino;
      this.openedDinos = 0;
      this.options = [];

      this.dinosaurs.forEach((element, i) => {
        this.options.push({
          path: element.picture,
        })

        if (element.completed.length) {
          this.openedDinos++;
        }
      })
    })
  }

  openDinosaurViewPopup(dino: any) {
    if (!dino.completed.length) return
    const dialog = this._dialog.open(DinoViewPopupComponent, {
      panelClass: 'medium-adaptive-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      disableClose: true,
      /* width: '32rem',
      height: '40rem', */
      data: dino,
    })
  }

  playAnim(event: Event) {
    ; (<HTMLAudioElement>event.target).play()
  }

  stopAnim(event: Event) {
    ; (<HTMLAudioElement>event.target).pause()
  }
}
