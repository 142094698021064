import { Component, Inject, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { Subject } from 'rxjs'
import { map, takeUntil } from 'rxjs/operators'
import { IAssignmentRequest } from '../../../../models/Assignment.model'
import { AddAssignment, AddAssignmentSuccess, EAssignmentActions } from '../../../../store/actions/assignment.actions'
import { IAppState } from '../../../../store/state/app.state'
import { NotificationService } from 'src/app/services/notification.service'
import { TranslateService } from '@ngx-translate/core'

@Component({
  templateUrl: './add-assignment.component.html',
  styleUrls: ['./add-assignment.component.scss'],
})
export class AddAssignmentDialogComponent implements OnInit {
  public form: UntypedFormGroup

  private destroyed$ = new Subject()

  public minDate = new Date();

  constructor(
    private _actions$: Actions,
    public dialogRef: MatDialogRef<AddAssignmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _store: Store<IAppState>,
    private _notificationService: NotificationService,
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this._actions$
      .pipe(
        takeUntil(this.destroyed$),
        ofType<AddAssignmentSuccess>(EAssignmentActions.AddAssignmentSuccess),
        map((res: AddAssignmentSuccess) => res.assignment)
      )
      .subscribe((assignment) => {
        this.dialogRef.close(assignment)
        this._notificationService.showNotification(this.translate.instant('LESSON_PLAN.ASSIGNMENT.CREATED'), 2)
      })

    this.form = new UntypedFormGroup({
      dueDate: new UntypedFormControl('', [Validators.required]),
      lessonId: new UntypedFormControl('', [Validators.required]),
    })

    this.form.patchValue({
      lessonId: this.data.lessonId,
    })
  }

  addAssignment(form: UntypedFormGroup) {
    if (form.invalid) return

    const request: IAssignmentRequest = this.form.value

    this._store.dispatch(new AddAssignment(this.data.schoolId, this.data.classroomId, request))
  }
}
