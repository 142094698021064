import { Component, OnInit, Inject } from '@angular/core'
import { LangChangeEvent, TranslateService } from '@ngx-translate/core'
import { DOCUMENT } from '@angular/common'
import { Router, ActivatedRoute } from '@angular/router'
import { GetIpUserService } from 'src/app/services/getIp.service'
import { SEOService } from 'src/app/services/seo.service'
import { langForms } from 'src/assets/i18n/langforms'
import { LocalizeHelperService } from 'src/app/services/localizHelper.service'

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(
    public translate: TranslateService,
    @Inject(DOCUMENT) private _document,
    private router: Router,
    private getUserIpService: GetIpUserService,
    private _seoService: SEOService,
    private route: ActivatedRoute,
    private localizeHelperService: LocalizeHelperService
  ) {}

  public currentLang: string
  public name: string
  public title: string
  public description: string
  public metaTitle = ''
  public lang = 'language'

  public url: string = this.router.url

  public langParam = this.route.snapshot.data.lang

  public lURL

  ngOnInit() {
    const { meta } = this.route.snapshot.data.meta
    const request = {
      url: this.url,
    }

    this.currentLang = this.localizeHelperService.getLangForced()
    this.lURL = this.localizeHelperService.getURLLang()

    const localMeta = meta[this.currentLang] == undefined ? meta['en'] : meta[this.currentLang]

    this._seoService.updateTitle(localMeta.title)
    this._seoService.updateDescription(localMeta.description)
    this._seoService.createCanonicalLink(localMeta.canonical)
    this.localizeHelperService.createLangMetaLinks()

    this.getUserIpService.setReviewSnippet(request, localMeta.reachName, this._document)

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this._seoService.updateTitle(meta[event.lang]['title'])
      this._seoService.updateDescription(meta[event.lang]['description'])
      this._seoService.createCanonicalLink(meta[event.lang]['canonical'])
      this.lURL = this.localizeHelperService.getURLLang()
      this.currentLang = event.lang
    })
  }

  public formatText(text: String, replacement) {
    return text.replace(/%s/g, replacement)
  }

  public formatFormS(text: String, replacement: String) {
    return text.replace(/%s/g, replacement + 's')
  }

  public formatFormEmpty(text: String) {
    return text.replace(/%s/g, '')
  }

  public formatWithForm(text) {
    if (!langForms[this.currentLang]) {
      return text
    }
    let simpleReplace = text.replace(/%s/g, langForms[this.currentLang][this.lang]['form1'])
    let other = simpleReplace.match(/%f[0-9]/g)
    if (other) {
      other.forEach((element, i) => {
        let form = `form${element.replace('%f', '')}`
        let replacement = Object.keys(langForms[this.currentLang]).length === 0 ? this.lang : langForms[this.currentLang][this.lang][form]
        simpleReplace = simpleReplace.replace(element, replacement)
      })
    }
    return simpleReplace
  }

  public getURLLangPart() {
    let p = this.lURL
    return p ? `/${p}` : ''
  }
}
