<div class="landing">
  <section class="mainComponent">
    <img src="assets/images/pictures/trees.png" class="trees" alt="trees" title="trees" />
    <img src="assets/images/pictures/mountain.png" class="mountain" alt="mountain" title="mountain" />
    <span class="rectRhombus orange"></span>
    <span class="rectRhombus small green"></span>
    <span class="rectCicrle red"></span>
    <span class="rectRhombus extraSmall orange"></span>
    <img src="assets/images/dinosaurs/oneDino.png" class="dino svgImage" />
    <article class="contentWrapper">
      <div class="text">
        <svg-icon class="svgImage" src="assets/images/dinosaurs/oneDino.svg"></svg-icon>
        <div class="titles">
          <h1 class="white baloo2">{{ 'HOME.HEADER.TITLE' | translate }}</h1>
          <span [innerHTML]="'HOME.HEADER.SUB_TITLE' | translate"></span>
          <button class="primary orange">
            <a class="dinoLink" routerLink="{{ getURLLangPart() }}/auth/registration">{{ 'HOME.BUTTONS.FREE' | translate
              }}</a>
          </button>
        </div>
      </div>
    </article>
  </section>
  <!-- / DONE -->

  <section class="languages">
    <img src="assets/images/pictures/borderWhite.png" class="border bottom" alt="" />
    <article class="contentWrapper">
      <a routerLink="{{ getURLLangPart() }}/learn-spanish-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/spanish.png" class="circle" alt="flag-of-spanish" title="spanish" alt="" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Spanish' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-french-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/french.png" class="circle" alt="flag-of-french" title="french" />
        </div>
        <span>{{ 'LANGUAGE_NAME.French' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-english-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/english.png" class="circle" alt="flag-of-english" title="english" />
        </div>
        <span>{{ 'LANGUAGE_NAME.English' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-german-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/german.png" class="circle" alt="flag-of-german" title="german" />
        </div>
        <span>{{ 'LANGUAGE_NAME.German' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-japanese-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/japanese.png" class="circle" alt="flag-of-japanese" title="japanese" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Japanese' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-russian-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/russian.png" class="circle" alt="flag-of-russian" title="russian" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Russian' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-hebrew-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/hebrew.png" class="circle" alt="flag-of-hebrew" title="hebrew" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Hebrew' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-chinese-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/chinese.png" class="circle" alt="flag-of-chinese" title="chinese" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Chinese' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-greek-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/greek.png" class="circle" alt="flag-of-greek" title="chinese" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Greek' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-polish-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/polish.png" class="circle" alt="flag-of-polish" title="polish" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Polish' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-portuguese-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/portuguese-br.png" class="circle" alt="flag-of-portuguese"
            title="portuguese" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Portuguese' | translate }}</span>
      </a>
      <a routerLink="{{ getURLLangPart() }}/learn-euportuguese-for-kids" class="languageCircle">
        <div class="circle">
          <img src="assets/images/languages/portuguese-eu.png" class="circle" alt="flag-of-eu-portuguese"
            title="eu-portuguese" />
        </div>
        <span>{{ 'LANGUAGE_NAME.Portuguese_eu' | translate }}</span>
      </a>
    </article>
    <button class="secondary blue">
      <a routerLink="{{ getURLLangPart() }}/language-courses">{{ 'HOME.HEADER.BUTTON' | translate }}</a>
    </button>
    <span class="rectCicrle blue blueHead"></span>
  </section>
  <!-- ??? -->

  <section class="about">
    <span class="rectCicrle red"></span>
    <span class="rectCicrle blue"></span>
    <article class="contentWrapper">
      <h2 class="blue baloo2">{{ 'HOME.BLOCK_1.TITLE' | translate }}</h2>
      <div class="twoCols">
        <div class="text">
          <div class="description">
            <p [innerHTML]="'HOME.BLOCK_1.TEXT' | translate"></p>
            <p>
              {{ 'HOME.BLOCK_1.TEXT2' | translate }}
            </p>
            <!-- ??? вопрос с приставкой /en в урлах страницы -->
            <button class="primary orange">
              <a class="dinoLink" routerLink="{{ getURLLangPart() }}/auth/registration">{{ 'HOME.BUTTONS.FREE' |
                translate }}</a>
            </button>
          </div>
        </div>

        <div class="image">
          <img class="svgImage" src="assets/images/dinosaurs/twoDinosaurs.png" />
        </div>
      </div>
    </article>
  </section>

  <section class="video">
    <img src="assets/images/pictures/borderWhite.png" alt="border" class="border top" />
    <img src="assets/images/pictures/borderWhite.png" alt="border" class="border bottom" />
    <article class="contentWrapper">
      <video controls controlsList="nodownload" *ngIf="currentLang == 'ua'">
        <source src="https://static.dinolingo.com/video/local/wid_ukrainian.mp4" type="video/mp4" />
      </video>
      <video controls controlsList="nodownload" *ngIf="currentLang == 'ru'">
        <source src="https://static.dinolingo.com/video/local/wid_russian.mp4" type="video/mp4" />
      </video>
      <video controls controlsList="nodownload" *ngIf="currentLang == 'zh'">
        <source src="https://static.dinolingo.com/video/local/wid_chinese_mandarin.mp4" type="video/mp4" />
      </video>
      <video controls controlsList="nodownload" *ngIf="currentLang == 'fr'">
        <source src="https://static.dinolingo.com/video/local/wid_french.mp4" type="video/mp4" />
      </video>
      <video controls controlsList="nodownload" *ngIf="currentLang == 'hi'">
        <source src="https://static.dinolingo.com/video/local/wid_hindi.mp4" type="video/mp4" />
      </video>
      <video controls controlsList="nodownload" *ngIf="currentLang == 'pl'">
        <source src="https://static.dinolingo.com/video/local/wid_polish.mp4" type="video/mp4" />
      </video>
      <video controls controlsList="nodownload" *ngIf="currentLang == 'pt'">
        <source src="https://static.dinolingo.com/video/local/wid_portuguese_br.mp4" type="video/mp4" />
      </video>
      <video controls controlsList="nodownload" *ngIf="currentLang == 'es'">
        <source src="https://static.dinolingo.com/video/local/wid_spanish.mp4" type="video/mp4" />
      </video>
      <video controls controlsList="nodownload" *ngIf="currentLang == 'tr'">
        <source src="https://static.dinolingo.com/video/local/wid_turkish.mp4" type="video/mp4" />
      </video>
      <video controls controlsList="nodownload" *ngIf="currentLang == 'ar'">
        <source src="https://static.dinolingo.com/video/local/wid_arabic.mp4" type="video/mp4" />
      </video>
      <video controls controlsList="nodownload" *ngIf="currentLang == 'jp'">
        <source src="https://static.dinolingo.com/video/local/wid_japanese.mp4" type="video/mp4" />
      </video>
      <video controls controlsList="nodownload" *ngIf="currentLang == 'it'">
        <source src="https://static.dinolingo.com/video/local/wid_italian.mp4" type="video/mp4" />
      </video>
      <video controls controlsList="nodownload" *ngIf="
          currentLang !== 'ru' &&
          currentLang !== 'ua' &&
          currentLang !== 'zh' &&
          currentLang !== 'fr' &&
          currentLang !== 'hi' &&
          currentLang !== 'pl' &&
          currentLang !== 'pt' &&
          currentLang !== 'es' &&
          currentLang !== 'tr' &&
          currentLang !== 'ar' &&
          currentLang !== 'jp' &&
          currentLang !== 'it'
        ">
        <source src="https://static.dinolingo.com/video/dinolingo_welcome.mp4" type="video/mp4" />
      </video>
    </article>
  </section>

  <!-- / DONE -->
  <section class="howItWorks">
    <article class="contentWrapper">
      <h2 class="blue baloo2">{{ 'HOME.BLOCK_6.HOW_KIDS_LEARN_TITLE' | translate }}</h2>
      <div class="content">
        <div class="cart">
          <img src="assets/images/wrapElements/lessons-egg.png" alt="lesson icon" />

          <div class="info">
            <h4 class="red">{{ 'HOME.BLOCK_6.HOW_KIDS_LEARN_TEXT1_TITLE' | translate }}</h4>
            <p>{{ 'HOME.BLOCK_6.HOW_KIDS_LEARN_TEXT1' | translate }}</p>
          </div>
        </div>

        <div class="cart">
          <img src="assets/images/wrapElements/games-egg.png" alt="lesson icon" />

          <div class="info">
            <h4 class="red">{{ 'HOME.BLOCK_6.HOW_KIDS_LEARN_TEXT2_TITLE' | translate }}</h4>
            <p>{{ 'HOME.BLOCK_6.HOW_KIDS_LEARN_TEXT2' | translate }}</p>
          </div>
        </div>

        <div class="cart">
          <img src="assets/images/wrapElements/books-egg.png" alt="lesson icon" />

          <div class="info">
            <h4 class="red">{{ 'HOME.BLOCK_6.HOW_KIDS_LEARN_TEXT3_TITLE' | translate }}</h4>
            <p>{{ 'HOME.BLOCK_6.HOW_KIDS_LEARN_TEXT3' | translate }}</p>
          </div>
        </div>

        <div class="cart">
          <img src="assets/images/wrapElements/worksheets-egg.png" alt="lesson icon" />

          <div class="info">
            <h4 class="red">{{ 'HOME.BLOCK_6.HOW_KIDS_LEARN_TEXT4_TITLE' | translate }}</h4>
            <p>{{ 'HOME.BLOCK_6.HOW_KIDS_LEARN_TEXT4' | translate }}</p>
          </div>
        </div>
      </div>
      <span class="rectRhombus extraSmall red"></span>
      <span class="rectRhombus extraSmall green"></span>
      <span class="rectRhombus small orange"></span>
      <button class="primary orange">
        <a class="dinoLink" routerLink="{{ getURLLangPart() }}/auth/registration">{{ 'HOME.BUTTONS.FREE' | translate
          }}</a>
      </button>
    </article>
  </section>

  <section class="ourCourses noPadding appBlock">
    <img src="assets/images/pictures/borderWhite.png" class="border bottom" alt="" />
    <div class="image">
      <img src="assets/images/pictures/app-image.png" alt="Dinolingo review app" title="Courses subuser" />
    </div>
  </section>

  <section class="bestMethods">
    <article class="contentWrapper">
      <h2 class="blue baloo2">{{ 'HOME.BEST_METHODS.TITLE' | translate }}</h2>
      <p>{{ 'HOME.BEST_METHODS.SUB_TITLE' | translate }}</p>
      <ul>
        <ol>
          {{
          'HOME.BEST_METHODS.ITEM_1' | translate
          }}
        </ol>
        <ol>
          {{
          'HOME.BEST_METHODS.ITEM_2' | translate
          }}
        </ol>
        <ol>
          {{
          'HOME.BEST_METHODS.ITEM_3' | translate
          }}
        </ol>
        <ol>
          {{
          'HOME.BEST_METHODS.ITEM_4' | translate
          }}
        </ol>
        <ol>
          {{
          'HOME.BEST_METHODS.ITEM_5' | translate
          }}
        </ol>
        <ol>
          {{
          'HOME.BEST_METHODS.ITEM_6' | translate
          }}
        </ol>
        <ol>
          {{
          'HOME.BEST_METHODS.ITEM_7' | translate
          }}
        </ol>
        <ol>
          {{
          'HOME.BEST_METHODS.ITEM_8' | translate
          }}
        </ol>
        <ol>
          {{
          'HOME.BEST_METHODS.ITEM_9' | translate
          }}
        </ol>
        <ol>
          {{
          'HOME.BEST_METHODS.ITEM_10' | translate
          }}
        </ol>
      </ul>
    </article>
  </section>

  <section class="whatParentsSay">
    <article class="contentWrapper">
      <h2 class="blue baloo2">{{ 'HOME.BLOCK_2.TEXT' | translate }}</h2>
      <testimonials-section></testimonials-section>
    </article>
  </section>
  <!-- / DONE -->

  <section class="ourCourses white">
    <article class="contentWrapper">
      <h2 class="blue baloo2">{{ 'HOME.BLOCK_3.TITLE' | translate }}</h2>
      <p [innerHTML]="'HOME.BLOCK_3.TEXT' | translate"></p>
      <h4 class="red">{{ 'HOME.BLOCK_3A.TITLE' | translate }}</h4>
      <p [innerHTML]="formatWithForm('HOME.BLOCK_3A.TEXT' | translate)"></p>
      <h4 class="red">{{ 'HOME.BLOCK_3B.HOME_TITLE' | translate }}</h4>
      <p [innerHTML]="formatWithForm('HOME.BLOCK_3B.HOME_TEXT' | translate)"></p>
      <h4 class="red">{{ formatWithForm('HOME.BLOCK_3C.TITLE' | translate) }}</h4>
      <p [innerHTML]="formatWithForm('HOME.BLOCK_3C.TEXT' | translate)"></p>
      <h4 class="red">{{ 'HOME.BLOCK_3D.HOME_TITLE' | translate }}</h4>
      <p [innerHTML]="formatWithForm('HOME.BLOCK_3D.HOME_TEXT' | translate)"></p>
      <h4 class="red">{{ formatWithForm('HOME.BLOCK_3E.HOME_TITLE' | translate) }}</h4>
      <p [innerHTML]="formatWithForm('HOME.BLOCK_3E.TEXT' | translate)"></p>
      <h4 class="red">{{ formatWithForm('HOME.BLOCK_3F.TITLE' | translate) }}</h4>
      <p [innerHTML]="formatWithForm('HOME.BLOCK_3F.TEXT' | translate)"></p>
      <h4 class="red" [ngClass]="{ hideBlock: currentLang !== 'en' }">{{ formatText('HOME.BLOCK_3G.TITLE' | translate,
        lang) }}</h4>
      <p [ngClass]="{ hideBlock: currentLang !== 'en' }"
        [innerHTML]="formatText('HOME.BLOCK_3G.TEXT' | translate, lang)"></p>
      <h4 class="red">{{ formatWithForm('HOME.BLOCK_3H.TITLE' | translate) }}</h4>
      <p [innerHTML]="formatWithForm('HOME.BLOCK_3H.TEXT' | translate)"></p>
      <h4 class="red">{{ formatWithForm('HOME.BLOCK_3I.TITLE' | translate) }}</h4>
      <p [innerHTML]="formatWithForm('HOME.BLOCK_3I.TEXT' | translate)"></p>
      <h4 class="red">{{ formatWithForm('HOME.BLOCK_3J.TITLE' | translate) }}</h4>
      <p [innerHTML]="formatWithForm('HOME.BLOCK_3J.TEXT' | translate)"></p>
      <h4 class="red">{{ formatWithForm('HOME.BLOCK_3K.TITLE' | translate) }}</h4>
      <p [innerHTML]="formatWithForm('HOME.BLOCK_3K.TEXT' | translate)"></p>
    </article>
  </section>
  <!-- / DONE -->

  <section class="ourCourses noPadding appBlock">
    <img src="assets/images/pictures/borderWhite.png" class="border bottom" alt="" />
    <div class="image">
      <img src="assets/images/pictures/computer-image.png" alt="Dinolingo review website" title="Courses subuser" />
    </div>
  </section>

  <section class="white">
    <span class="rectCicrle blue"></span>
    <button class="primary orange">
      <a class="dinoLink" routerLink="{{ getURLLangPart() }}/auth/registration">{{ 'HOME.BUTTONS.FREE' | translate
        }}</a>
    </button>
  </section>
  <!-- / done -->

  <section class="forSchools gray">
    <img src="assets/images/borders/borderWhite.png" class="border top" alt="" />
    <article class="contentWrapper">
      <h2 class="baloo2 blue">
        {{ 'HOME.BLOCK_4.HOME_TITLE' | translate }}
      </h2>
      <div class="twoCols">
        <div class="text">
          <div class="description">
            <p [innerHTML]="formatText('HOME.BLOCK_4.TEXT' | translate, lang)"></p>
            <!-- ??? вопрос с приставкой /en в урлах страницы -->
            <a title="schools" routerLink="{{ getURLLangPart() }}/schools-teachers">{{ 'HOME.LINKS.DINO' | translate }}
            </a>
            <button class="primary orange">
              <a class="dinoLink" routerLink="{{ getURLLangPart() }}/auth/registration">{{ 'HOME.BUTTONS.FREE' |
                translate }}</a>
            </button>
          </div>
        </div>

        <div class="image">
          <svg-icon src="assets/images/dinosaurs/schoolDinos.svg"></svg-icon>
        </div>
      </div>
    </article>
    <review-stars [schemeTitle]="metaTitle"></review-stars>
  </section>
</div>