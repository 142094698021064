<div class="editAssignment">
  <div class="actions">
    <span class="title">{{ 'LESSON_PLAN.ASSIGNMENT.UPDATE' | translate }} </span>
    <span class="close" (click)="dialogRef.close()">+</span>
  </div>

  <div class="content">
    <div class="form" *ngIf="!deleteMode; else elseBlock">
      <form [formGroup]="form">
        <mat-form-field class="dueDate">
          <mat-label>{{ 'LESSON_PLAN.ASSIGNMENT.DUE_DATE' | translate }} </mat-label>
          <input matInput [matDatepicker]="picker" required formControlName="dueDate" [min]="minDate" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </form>
    </div>
    <ng-template #elseBlock>
      <div innerHTML="{{ 'LESSON_PLAN.ASSIGNMENT.DELETE_CONFIRM' | translate }}"></div>
    </ng-template>
  </div>

  <div class="buttons">
    <ng-container *ngIf="!deleteMode">
      <button mat-raised-button color="accent" [disabled]="!form.valid" (click)="editAssignment(form)">
        {{ 'LESSON_PLAN.ASSIGNMENT.BUTTON_UPDATE' | translate }}
      </button>
      <button mat-raised-button color="warn" (click)="deleteAssignment()">
        {{ 'LESSON_PLAN.ASSIGNMENT.BUTTON_DELETE' | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="deleteMode">
      <button mat-raised-button color="warn" (click)="confirmDelete()" [disabled]="deleteSecondsLeft > 0">
        <ng-template [ngIf]="deleteSecondsLeft > 0">({{ deleteSecondsLeft }})</ng-template>
        <ng-template [ngIf]="deleteSecondsLeft === 0">
          {{ 'LESSON_PLAN.ASSIGNMENT.BUTTON_DELETE_CONFIRM' | translate }}
        </ng-template>
      </button>
      <button mat-raised-button color="accent" (click)="cancelDelete()">
        {{ 'LESSON_PLAN.ASSIGNMENT.BUTTON_DELETE_CANCEL' | translate }}
      </button>
    </ng-container>
  </div>
</div>
