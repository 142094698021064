import { Component, HostListener, OnDestroy, OnInit } from '@angular/core'
import { IAppState } from 'src/app/store/state/app.state'
import { Store, select } from '@ngrx/store'
import { selectCurrentUser } from 'src/app/store/selectors/user.selectors'
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog'
import { ISubuser } from 'src/app/models/Subuser.model'
import { SelectSubuser, GetAllSubuser, GetSubuser } from 'src/app/store/actions/subuser.actions'
import { Actions } from '@ngrx/effects'
import { NotificationService } from 'src/app/services/notification.service'
import { selectAllSubuser, selectCurrentSubuser } from 'src/app/store/selectors/subuser.selectors'
import { filter, takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { Router } from '@angular/router'
import { DisplayHeaderLesson } from 'src/app/store/actions/lessons.actions'
import { GetUser } from 'src/app/store/actions/user.actions'
import { DeviceDetectorService } from 'ngx-device-detector'
import { AddSubuserDialogComponent } from 'src/app/shared/popups/Subuser-Dialogs/add-subuser/addSubuser.component'
import { SelectCourseDialogComponent } from 'src/app/shared/popups/Subuser-Dialogs/select-course/selectCourse.component'
import { DinosaursPopupComponent } from 'src/app/shared/popups/Achievement-Dialogs/dinosaurs/dinosaurs.component'
import { AchievementsPopupComponent } from 'src/app/shared/popups/Achievement-Dialogs/achievements/achievements.component'
import { StarsPopupComponent } from 'src/app/shared/popups/Achievement-Dialogs/stars/stars.component'
import { CurrentUserPopupComponent } from 'src/app/shared/popups/Parent-Dialogs/current-user/current-user.component'
import { SubUserStoreService } from "src/app/store/services/subuser-store.service"

@Component({
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  public subusers: Array<ISubuser>
  public currentSubuser: ISubuser
  public isMobile = false
  public deviceInfo = this.deviceService.getDeviceInfo()
  public userRole
  public subuserCount = 1

  constructor(
    private _store: Store<IAppState>,
    private deviceService: DeviceDetectorService,
    private _dialog: MatDialog,
    public notifications: NotificationService,
    public router: Router, // private socket: Socket
    public subUserStoreService: SubUserStoreService
  ) {
    this._store
      .pipe(
        takeUntil(this.unsubscribe$),
        select(selectAllSubuser),
        filter((subusers) => !!subusers)
      )
      .subscribe((subusers) => {
        this.subusers = subusers
      })
  }
  public unsubscribe$ = new Subject()
  public user$ = this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentUser))
  public subuser: ISubuser

  isMobileScreen: boolean = false

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    if (this.deviceService.isMobile()) {
      this.isMobile = true
      this.checkScreenSize()
    }
  }

  ngOnInit() {
    if (this.deviceService.isMobile()) {
      this.isMobile = true
      this.checkScreenSize()
    }

    this.user$.subscribe((user) => {
      if (!user) { return }

      this.subuserCount = user.user.subusers.length
      this.userRole = user.userType
    })

    this._store.dispatch(new DisplayHeaderLesson())

    this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentSubuser)).subscribe((subuser) => {
      if (subuser) {
        this.subuser = subuser
        if (!subuser.studentId) {
          this.subUserStoreService.currentSubuser = JSON.stringify(subuser)
        }
      }
    })
  }

  private checkScreenSize(): void {
    this.isMobileScreen = window.outerHeight < 500 || window.outerWidth < 500
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  openDinosaursPopup(event) {
    const dialog = this._dialog.open(DinosaursPopupComponent, {
      panelClass: 'big-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
    })
  }

  openAchievementsPopup(event) {
    const dialog = this._dialog.open(AchievementsPopupComponent, {
      // panelClass: 'big-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
    })
  }

  openStarsPopup(event) {
    const dialog = this._dialog.open(StarsPopupComponent, {
      panelClass: 'transparentDialog',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: this.subuser,
    })
  }

  openCurrentUserPopup() {
    const dialog = this._dialog.open(CurrentUserPopupComponent, {
      closeOnNavigation: true,
      hasBackdrop: true,
      panelClass: 'full-mobile-screen',
    })

    dialog.afterOpened().subscribe((result) => {
      this.notifications.showNotification('Please, select your user!', 4)
    })

    dialog.afterClosed().subscribe((subuser) => {
      if (!subuser) {
        return
      }
      this.notifications.showNotification(`Hello, ${subuser.name}`, 2)
      this.subUserStoreService.currentSubuser = JSON.stringify(subuser)
      this._store.dispatch(new SelectSubuser(subuser))
      this._store.dispatch(new GetSubuser(subuser.id))
    })
  }

  openAddSubuserPopup() {
    const dialog = this._dialog.open(AddSubuserDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: '28rem',
      height: '28rem',
    })

    dialog.afterClosed().subscribe((res) => {
      this._store.dispatch(new GetAllSubuser())
    })
  }

  openSelectCoursePopup(event) {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.disableClose = false
    dialogConfig.hasBackdrop = true
    dialogConfig.panelClass = 'menuDialog'

    this._dialog
      .open(SelectCourseDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe(() => {
        this._store.dispatch(new GetUser())
      })
  }
}
