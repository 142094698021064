import { Component, OnDestroy, OnInit } from '@angular/core'
import { LangChangeEvent, TranslateService } from '@ngx-translate/core'
import { PaymentService } from 'src/app/services/payment.service'
import { Router } from '@angular/router'
import { Store, select } from '@ngrx/store'
import { IAppState } from 'src/app/store/state/app.state'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { IUser } from 'src/app/models/User.model'
import { selectCurrentUser } from 'src/app/store/selectors/user.selectors'
import { SpinnerOverlayService } from 'src/app/services/spinner.service'
import { NotificationService } from 'src/app/services/notification.service'
import { Meta, Title } from '@angular/platform-browser'
import { selectCustomer } from 'src/app/store/selectors/payment.selectors'
import { GetCustomer } from 'src/app/store/actions/payment.actions'
import { FormatTextService } from '../../../../../services/formatText.service'
import { SubuserService } from 'src/app/services/subuser.service'
import { environment } from 'src/environments/environment'
import { SubUserStoreService } from "src/app/store/services/subuser-store.service"

@Component({
  templateUrl: 'stripe.component.html',
  styleUrls: ['stripe.component.scss'],
})
export class SubscriptionsStripeComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject()
  public plan: any
  public user: IUser
  public actualSubscriptions

  public plans
  public selectedPlans = []
  public $plansInfo = []
  public $totalCost = 0
  public $totalDiscountCost = 0
  public plansData = []
  public ultimatePosition = -1
  public $noUltimateDisabled = false
  public purchasedPlans = []
  public $selectedList = []
  public $disabledList = []
  public paymentMethod

  public courseId: number

  public currentLang
  public customer
  public period = null

  public couponCode = null
  public couponData = null
  public couponError = false

  public canUseSecondCCoupon = false

  public iAgree = false
  public trialUsed = null

  public firstName = ''
  public lastName = ''

  public selectedMethod = 'stripe'

  constructor(
    private _paymentService: PaymentService,
    private router: Router,
    private _store: Store<IAppState>,
    public _spinnerService: SpinnerOverlayService,
    public _notificationService: NotificationService,
    public translate: TranslateService,
    private titleService: Title,
    private meta: Meta,
    private textService: FormatTextService,
    public _subuserService: SubuserService,
    public subUserStoreService: SubUserStoreService
  ) { }

  public formatLangName(lang) {
    return this.textService.getFullLangName(lang)
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  activeTab: string = 'autumn'

  activateTab(tab: string): void {
    this.activeTab = tab
  }

  ngOnInit() {
    this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentUser)).subscribe((user) => {
      this.user = user.user
      this.actualSubscriptions = user.actualSubscriptions
    })

    this.translate.use(this.subUserStoreService.currentLanguage)

    const userId = this.subUserStoreService.userId

    this._store.dispatch(new GetCustomer(userId))

    this._store.pipe(takeUntil(this.unsubscribe$), select(selectCustomer)).subscribe((customer) => {
      if (customer) {
        this.firstName = customer.firstName
        this.lastName = customer.lastName
        this.customer = customer
        this.trialUsed = customer.trialUsed
      }
    })

    this.currentLang = this.subUserStoreService.currentLanguage
    this.translate.use(this.currentLang).subscribe((resInit) => {
      if (typeof resInit !== 'undefined') {
        this.translate.get(resInit.HOME.META.TITLE.STRIPE).subscribe((res: string) => {
          this.titleService.setTitle(res)
        })

        this.translate.get(resInit.HOME.META.DESCRIPTION.STRIPE).subscribe((res: string) => {
          this.meta.updateTag({ name: 'description', content: res })
        })
      }
    })

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLang = event.lang
      this.translate.get('HOME.META.TITLE.STRIPE').subscribe((res: string) => {
        this.titleService.setTitle(res)
      })

      this.translate.get('HOME.META.DESCRIPTION.STRIPE').subscribe((res: string) => {
        this.meta.updateTag({ name: 'description', content: res })
      })
    })
  }

  manageSubscription() {
    window.open('https://help.dinolingo.com/article/378-cancel-unsubscribe-manage-subscription-account', '_blank').focus()
  }

  isTrialActive() {
    if (!this.actualSubscriptions) { return null }

    return this.actualSubscriptions && this.actualSubscriptions.some((subscription) => subscription.status === 'trialing')
  }

  isPending() {
    if (!this.actualSubscriptions) { return false }

    return this.actualSubscriptions && this.actualSubscriptions.some((subscription) => subscription.status.toLowerCase().includes('pending'))
  }

  anyActualSubscription() {
    if (!this.actualSubscriptions) { return null }

    return this.actualSubscriptions && this.actualSubscriptions.length > 0 && this.actualSubscriptions.some((subscription) => subscription.status === 'active')
  }

  checkoutSession(period: string) {
    if (this.selectedMethod === 'stripe') {
      return this.createStripeCheckoutSession(period === 'yearly' ? environment.annualPlanId : environment.monthlyPlanId)
    }

    return this.createPayPalCheckoutSession(period === 'yearly' ? environment.ppAnnualPlanId : environment.ppMonthlyPlanId)
  }

  createPayPalCheckoutSession(priceId: string) {
    this._paymentService.createPayPalCheckoutSession({ email: this.user.email, priceId, userId: this.user.id }).subscribe((session: any) => {
      this.redirectToSuccessPage(session)
    })
  }

  createStripeCheckoutSession(priceId: string) {
    this._paymentService.createCheckoutSession({ email: this.user.email, priceId }).subscribe((session: any) => {
      this.redirectToSuccessPage(session)
    })
  }

  redirectToSuccessPage(session: any) {
    if (session && session.url) {
      window.location.href = session.url
    }
  }
}
