import { Component, Inject, OnInit } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog'
import { TranslateService } from '@ngx-translate/core'
import { LessonsService } from 'src/app/services/lessons.service'
import { downloadPdfLink, isIpad, openPdfLink } from 'src/app/helpers/utils/LessonUtil/lessonTest.util'

interface TestPlan {
  wordsList: Array<string>
  wordsCount: number
  pdf: string
}
@Component({
  templateUrl: 'test-plan.component.html',
  styleUrls: ['test-plan.component.scss'],
})
export class TestPlanComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<TestPlanComponent>, public translate: TranslateService, @Inject(MAT_DIALOG_DATA) public data, public _lessonService: LessonsService) { }
  public lessonArray
  public pdf: string

  ngOnInit() {
    this._lessonService.getLessonWordsList(this.data.id).subscribe((res) => {
      this.lessonArray = res
    })
  }

  showPdf() {
    openPdfLink(this.lessonArray.pdf)
  }

  downloadPdf() {
    downloadPdfLink(this.lessonArray.pdf, `${this.data.name}.pdf`, false, isIpad())
  }
}
