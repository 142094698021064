// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'dev',
  production: false,
  appVersion: require('../../package.json').version + '-stage',
  stripeKey: 'pk_test_X27kHvz41XvhcIJ2FJwGod16',
  googleApiKey:
    '725259782118-6fe2ncldvsn9in8nmraprcomta9u4ll0.apps.googleusercontent.com',
  facebookApiKey: '2787826584618529',
  gatewayUrl: 'https://gateway.dev.dinolingo.com/',
  stripeCustomerPortal: 'https://billing.stripe.com/p/login/test_28odQXgv65rEfqo288',
  monthlyPlanId: 'price_1IG1U54V1uixoOB2bhOYfdq8',
  annualPlanId: 'price_1OjOa34V1uixoOB2BSKwaiG1',
  ppMonthlyPlanId: 'P-1CL13638NE868041PM2MUFRY',
  ppAnnualPlanId: 'P-7GE07749RU118752CM2MUDTY',
  logoutRedirectUrl: 'https://dev.dinolingo.com/auth/login',
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
