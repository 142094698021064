import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'

@Injectable()
export class NoneAuthGuard implements CanActivate {
  constructor(private _router: Router) {}

  canActivate() {
    const jwtToken = localStorage.getItem('accessToken') || null
    if (!jwtToken) {
      return true
    }
    this._router.navigate(['/profile/lessons'])
  }
}
